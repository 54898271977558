import { Component, OnInit, SecurityContext } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { AccountService } from "app/account/account.service";
import { MerchantPaymentFilter } from "app/bills/_shared/model/bill-filter";
import { UtilitiesService } from "app/utilities/utilities.service";
import { CommonService } from "app/_core/common.service";
import { ExportToExcelService } from "app/_core/export-to-excel.service";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { Utility } from "app/_shared/model/utility";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { PaymentsService } from "../payments.service";
import { MerchantPayment } from "../_shared/model/merchant-payment";
import * as _ from "underscore";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-ach-payments-report",
  templateUrl: "./ach-payments-report.component.html",
  styleUrls: ["./ach-payments-report.component.css"],
})
export class AchPaymentsReportComponent implements OnInit {
  pageNumber: number;
  merchantPaymentFilter: MerchantPaymentFilter;
  paymentHistoryList: MerchantPayment[];
  paginationVM = new PaginationVM<MerchantPayment, MerchantPaymentFilter>();
  merchantId: string;
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  utilities: Utility[];
  data: any;
  today = new Date();
  fToday = new Date();
  toDate: Date;
  minDate: Date;
  isPendingPayment: boolean = false;
  constructor(
    private router: Router,
    private sanitiser: DomSanitizer,
    private paymentsService: PaymentsService,
    private commonService: CommonService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private exportToExcelService: ExportToExcelService,
    private toaster: ToastrService
  ) {
    this.merchantId = this.commonService.getMerchantUserId();
    this.pageNumber = 1;
    this.utilities = new Array<Utility>();
    this.paymentHistoryList = [];
    this.merchantPaymentFilter = new MerchantPaymentFilter();
    this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.paymentHistoryList,
      filters: this.merchantPaymentFilter,
    };
    this.isPendingPayment = false;

    this.getUtilities();
  }

  ngOnInit() {
    if (this.router.url === "/report/ach-payments") {
      this.isPendingPayment = true;
    }
  }

  /* get payment list */
  getPayment() {
    this.spinner.show();
    this.paymentsService.getACHPaymentHistory(this.merchantId, this.paginationVM).subscribe(
      (res) => {
        this.paymentHistoryList = [];
        if (res) {
          this.paginationVM = res;
          this.paymentHistoryList = new Array<MerchantPayment>();
          this.paymentHistoryList = this.paginationVM.records;
          if (this.increase) {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          } else if (this.decrease) {
            this.showingPage = this.showingPage - this.oldPageLength;
          } else {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          }
          this.oldPageLength = this.paginationVM.records.length;
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
          this.increase = false;
          this.decrease = false;
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  /* get merchant utilities */
  getUtilities() {
    // this.spinner.show();
    this.accountService.getUtilitiesFromMerchant(this.merchantId, false).subscribe((res) => {
      if (res) {
        // this.spinner.hide();
        this.utilities = res;
        this.merchantPaymentFilter.utilityId = this.utilities[0].id;
        this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
          pageNumber: 0,
          pageSize: 10,
          totalCount: 0,
          sortBy: "Id",
          sortType: "desc",
          records: this.paymentHistoryList,
          filters: this.merchantPaymentFilter,
        };
        this.merchantPaymentFilter.cardType = "ACH";
        this.merchantPaymentFilter.paymentStatus = this.isPendingPayment ? "Pending" : "Approved";
        if (!this.isPendingPayment) {
          this.merchantPaymentFilter.from = this.commonService.currentDateFormat(
            new Date().getFullYear(),
            new Date().getMonth(),
            1,
            0,
            0,
            0,
            0
          );
          this.merchantPaymentFilter.to = this.commonService.currentDateFormat(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0,
            23,
            59,
            0,
            0
          );
        }
        this.getPayment();
      }
      (err) => {
        this.spinner.hide();
      };
    });
  }

  search() {
    this.showingPage = 0;
    this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.paymentHistoryList,
      filters: this.merchantPaymentFilter,
    };
    this.merchantPaymentFilter.cardType = "ACH";
    this.merchantPaymentFilter.paymentStatus = this.isPendingPayment ? "Pending" : "Approved";
    this.getPayment();
  }

  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getPayment();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getPayment();
    }
  }

  /* reset payment foorm */
  reset(form: NgForm) {
    this.merchantPaymentFilter = new MerchantPaymentFilter();
    this.paymentHistoryList = [];
    this.showingPage = 0;
    //form.resetForm();
    this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
      pageNumber: 0,
      //pageSize: 10,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.paymentHistoryList,
      filters: this.merchantPaymentFilter,
    };
    this.merchantPaymentFilter.utilityId = this.utilities[0].id;
    this.merchantPaymentFilter.cardType = "ACH";
    this.merchantPaymentFilter.paymentStatus = "all";
    if (!this.isPendingPayment) {
      this.merchantPaymentFilter.from = this.commonService.currentDateFormat(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        0,
        0,
        0,
        0
      );
      this.merchantPaymentFilter.to = this.commonService.currentDateFormat(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0,
        23,
        59,
        0,
        0
      );
    }
    this.paginationVM.filters = this.merchantPaymentFilter;
    this.getUtilities();
  }

  /* opt in list export to excel */
  exportToExcel() {
    this.spinner.show();
    const pagination = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
      pageNumber: 0,
      pageSize: this.paginationVM.totalCount,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: new Array<MerchantPayment>(),
      filters: this.merchantPaymentFilter,
    };
    this.paymentsService.getACHPaymentHistory(this.merchantId, pagination).subscribe(
      (res) => {
        if (res) {
          res.records.forEach((item) => {
            const due = new Date(item.dueDate);
            const paymentDate = new Date(item.transactionDate);
            item.dueDate = this.commonService.formatDueDate(due);
            item.transactionDate = this.commonService.formatDueDate(paymentDate);
            item.utilityName = item.utility.name;
          });
          var labels: any;
          var data: any;
          data = _.map(res.records, function (o) {
            return _.pick(
              o,
              "transactionReference",
              "transactionDate",
              "amountPaid",
              "dueDate",
              "accountNumber",
              "name",
              "consumerName",
              "utilityName",
              "paymentStatus"
            );
          });
          labels =
            "Transaction ref, Transaction Date, Amount Paid ($), Due Date, Account No.,Consumer Name, Paid By,Utility Name, Payment Status";

          const today = new Date();
          const date = this.commonService.formatDate(today);
          this.exportToExcelService.download(data, "Report_" + date, labels);
          this.toaster.success("The request data-sheet created successfully.", "");
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
}
