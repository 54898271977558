import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
declare var $: any;

@Component({
  selector: 'app-privacy-palicy',
  templateUrl: './privacy-palicy.component.html',

})
export class PrivacyPalicyComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    $('.height-terms-condition').each(function() {
			var myHeight = $(window).height() - 190;
			$(this).slimscroll({
				height: myHeight
			});
    });
  }

  goToLink(url: string){
    window.open(url, "_blank");
}

}
