import { Injectable } from '@angular/core';
import { DataService } from 'app/_core/data.service';
import { PublishBill } from './_shared/model/publish-bill';
import { PublishBillFilter } from './_shared/model/publish-bill-filter';
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { Observable } from 'rxjs';
import { PublishModel } from './_shared/model/publish-model';
import { BillSummary } from './_shared/model/bill-summary';
import { MerchantBill } from 'app/_shared/model/merchant-bill';
import { MerchantBillFilter } from 'app/bills/_shared/model/bill-filter';
import { BillSummaryEmailsVM } from './_shared/model/bill-summary-emails-vm';

@Injectable({
  providedIn: 'root'
})
export class PublishService {

  constructor(private dataService: DataService) { }

  getPublishBillList(merchantId: string, merchantBill: PaginationVM<PublishBill, PublishBillFilter>):Observable<PaginationVM<PublishBill, PublishBillFilter>> {
    return this.dataService.post<PaginationVM<PublishBill, PublishBillFilter>, PaginationVM<PublishBill, PublishBillFilter>>(
      `merchant/Publish/${merchantId}`,
      merchantBill
    );
  }

  PublishBill(publish: PublishModel): Observable<any> {
		return this.dataService.post<any, PublishModel>('merchant/Publish/PublishBill', publish);
  }

  UnPublishBill(publish: PublishModel): Observable<any> {
		return this.dataService.post<any, PublishModel>('merchant/Publish/UnpublishBill', publish);
  }

  PublishAll(publish: PublishModel): Observable<any> {
		return this.dataService.post<any, PublishModel>('merchant/Publish/publishAll', publish);
  }

  RejectAll(publish: PublishModel): Observable<any> {
		return this.dataService.post<any, PublishModel>('merchant/Publish/rejectall', publish);
  }

  ResendEmail(billId: string, merchantId: string, utilityId: string,account: string, emails: string): Observable<any> {
    return this.dataService.get(`merchant/consumers/${billId}/${merchantId}/${utilityId}/${account}/${emails}`);
  }

  LoadBillSummary(merchantId: string, utilityId: string,billId: string, accountNumber): Observable<BillSummaryEmailsVM> {
    // tslint:disable-next-line: max-line-length
    return this.dataService.get(`merchant/Publish/GetBillSummary/${merchantId}/${utilityId}/${billId}/${accountNumber}`);
  }

  getBillList(
    merchantId: string,
    merchantBill: PaginationVM<MerchantBill, MerchantBillFilter>
  ): Observable<PaginationVM<MerchantBill, MerchantBillFilter>> {
    return this.dataService.post<
      PaginationVM<MerchantBill, MerchantBillFilter>,
      PaginationVM<MerchantBill, MerchantBillFilter>
    >(`merchant/Publish/Bill/${merchantId}`, merchantBill);
  }

}
