import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NewCustomer } from "../_shared/new-customer";
import { NgForm } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BillerService } from "../biller.service";
import { CommonService } from "app/_core/common.service";
import { UtilitiesService } from "app/utilities/utilities.service";
import { MerchantUtilitySales } from "app/dashboard/_shared/model/merchant-utility-sales";
import { AccountService } from "app/account/account.service";
import { Utility } from "app/_shared/model/utility";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { Customers, CustomerFilter } from "../_shared/customers";
import { takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { ExportToExcelService } from "app/_core/export-to-excel.service";
declare var intellipay: any;
import * as _ from "underscore";
import { Location } from "@angular/common";
import { Constant } from "app/_shared/constant";

@Component({
  selector: "app-add-new-user",
  templateUrl: "./add-new-user.component.html",
  styleUrls: ["./add-new-user.component.css"],
})
export class AddNewUserComponent implements OnInit {
  @ViewChild("payNowDashboard", { static: false }) payNowDashboard: ElementRef;
  @ViewChild("payNowDashboard1", { static: false }) payNowDashboard1: ElementRef;
  @ViewChild("payNowDashboard2", { static: false }) payNowDashboard2: ElementRef;
  @ViewChild('payNowDashboard3', { static: false }) payNowDashboard3!: ElementRef;
  private unsubscribe$ = new Subject<void>();
  customer: NewCustomer;
  openForm: boolean;
  merchantId: string;
  utilities: Utility[];
  paginationVM = new PaginationVM<Customers, CustomerFilter>();
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  pageNumber: number;
  customers: Customers[];
  customerFilter: CustomerFilter;
  today = new Date();
  fToday = new Date();
  toDate: Date;
  IsSearching: boolean;
  isAdhocPayment: boolean;
  adhocPayment: boolean;
  urls: string;
  amountPattern = Constant.AMOUNT;
  interval: any;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  emailPattern = Constant.EMAIL_REGEXP;
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private billerService: BillerService,
    private commonService: CommonService,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private exportToExcelService: ExportToExcelService,
    private location: Location
  ) {
    this.merchantId = this.commonService.getMerchantUserId();

    this.customer = new NewCustomer();
    this.customers = new Array<Customers>();
    this.customerFilter = new CustomerFilter();
    this.openForm = false;
    this.IsSearching = true;
    this.utilities = new Array<Utility>();
    this.customerFilter.from = this.firstDayOfMonthDateFormat();
    this.customerFilter.to = new Date();
    this.paginationVM = <PaginationVM<Customers, CustomerFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.customers,
      filters: this.customerFilter,
    };
    this.adhocPayment = false;

  }

  ngOnInit() {
    if (this.router.url === "/biller-support/new-customer") {
      this.adhocPayment = true;
    }
    this.getUtilities();
    this.activatedRoute.queryParams
      .pipe(
        tap((queryParams: Params) => {
          this.isAdhocPayment = queryParams["isAdhoc"];
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        () => {
          this.openForm = this.isAdhocPayment;
          this.IsSearching = !this.isAdhocPayment;
        },
        (err) => {}
      );
    // this.callIntellipay(this.merchantId);
    // this.getNewCustomers();
  }

  onValueChange(fromDate) {
    if (fromDate) {
      this.fToday = fromDate;
    } else {
      this.fToday = this.today;
    }
  }

  firstDayOfMonthDateFormat() {
    const t = new Date();
    return new Date(t.getFullYear(), t.getMonth(), 1, 0, 0, 0, 0);
  }

  /*Get list of utilities registered for perticular merchant*/
  getUtilities() {
    this.spinner.show();
    this.accountService.merchantUtility(this.merchantId).subscribe(
      (res) => {
        if (res) {
          this.utilities = res;
          this.customer.utility.id = this.utilities[0].id;
          this.getNewCustomers();
          //this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  getNewCustomers() {
    this.spinner.show();
    this.billerService.getCustomers(this.merchantId, this.paginationVM).subscribe(
      (res) => {
        if (res) {
          this.paginationVM = res;
          this.customers = new Array<Customers>();
          this.customers = this.paginationVM.records;
          this.oldPageLength = this.paginationVM.records.length;
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
          // if (this.increase) {
          //   this.showingPage = this.showingPage + this.paginationVM.records.length;
          // } else if (this.decrease) {
          //   this.showingPage = this.showingPage - this.oldPageLength;
          // } else {
            this.showingPage = ((this.pageNumber -1) * this.paginationVM.pageSize) + this.paginationVM.records.length;
          // }
          this.increase = false;
          this.decrease = false;
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  searchCustomers() {
    this.showingPage = 0;
    this.paginationVM = <PaginationVM<Customers, CustomerFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.customers,
      filters: this.customerFilter,
    };
    if (this.customerFilter.from != null) {
      // tslint:disable-next-line: max-line-length
      this.customerFilter.from = this.commonService.currentDateFormat(
        this.customerFilter.from.getFullYear(),
        this.customerFilter.from.getMonth(),
        this.customerFilter.from.getDate(),
        0,
        0,
        0,
        0
      );
    }
    if (this.customerFilter.to != null) {
      // tslint:disable-next-line: max-line-length
      this.customerFilter.to = this.commonService.currentDateFormat(
        this.customerFilter.to.getFullYear(),
        this.customerFilter.to.getMonth(),
        this.customerFilter.to.getDate(),
        23,
        59,
        0,
        0
      );
    }
    this.paginationVM.filters = this.customerFilter;
    this.getNewCustomers();
  }

  resetSearch(form: NgForm) {
    this.customerFilter = new CustomerFilter();
    this.customers = [];
    this.showingPage = 0;
    form.resetForm();
    this.paginationVM = <PaginationVM<Customers, CustomerFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.customers,
      filters: this.customerFilter,
    };
    this.paginationVM.filters = this.customerFilter;
    this.customerFilter.from = this.firstDayOfMonthDateFormat();
    this.customerFilter.to = new Date();
    this.getUtilities();
  }

  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getNewCustomers();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getNewCustomers();
    }
  }

  cancel(form: NgForm) {
    form.resetForm();
    this.adhocPayment = true;
    this.IsSearching = true;
    this.openForm = false;
    // this.callIntellipay(this.merchantId);
  }

  openCustomerForm(isOpen: boolean) {
    this.openForm = isOpen;
    this.IsSearching = !isOpen;
    this.adhocPayment = false;
    this.getUtilities();
    // this.callIntellipay(this.merchantId);
  }

  addCustomer() {
    this.payNowDashboard3.nativeElement.attributes[3].nodeValue = 'Adhoc';
    this.payNowDashboard1.nativeElement.attributes[7].nodeValue = this.customer.amount;
    this.payNowDashboard2.nativeElement.attributes[7].nodeValue = this.customer.accountNumber + "-" + this.customer.prefix;
    this.payNowDashboard.nativeElement.click();
  }

  reset(form: NgForm) {
    form.resetForm();
    this.getUtilities();
  }

  callIntellipay(form: NgForm, customer: Customers) {
    if (form.valid) {
      this.spinner.show();
      this.billerService.lightboxEmvIn(this.merchantId, customer.utility.id).subscribe((res) => {
        if (res) {
          const rg = document.createRange();
          document.documentElement.appendChild(rg.createContextualFragment(res.result));
          intellipay.setItemLabel("account", "Account Number");
          intellipay.setItemLabel("button", "Pay Now"); //Set the pay button text
          intellipay.disable("email");
          intellipay.initialize();
          // this.spinner.hide();
          const _this = this;
          intellipay.runOnApproval(function (response) {
           // intellipay.closeModal();
            _this.storeNewCustomerPayment(JSON.stringify(response));
          });
          // setTimeout(function () {
          //   _this.addCustomer();
          //   _this.spinner.hide();
          // }, 1000);
          this.interval = setInterval(() => {
            if (intellipay.isReady) {
              clearInterval(this.interval);
              _this.addCustomer();
            }
          }, 2000);
        }
      });
    }
  }

  storeNewCustomerPayment(response) {
    this.customer.intellipayResponse = response;
    this.customer.merchant.id = this.merchantId;
    this.billerService.storecustomerIntelliPayResponse(this.customer).subscribe((res) => {
      if (res) {
        // this.IsSearching = true;
        // this.openForm = false;
        // this.adhocPayment = true;
        //this.showingPage = 10;
        this.customer = new NewCustomer();
        this.customer.utility.id = this.utilities[0].id;
        this.toaster.success("Adhoc payment successfull");
        // this.paginationVM = <PaginationVM<Customers, CustomerFilter>>{
        //   pageNumber: 0,
        //   pageSize: 10,
        //   totalCount: 0,
        //   sortBy: "Id",
        //   sortType: "desc",
        //   records: this.customers,
        //   filters: this.customerFilter,
        // }
        // this.getNewCustomers();
        // this.router.navigate(["/biller-support/demand-receipt"], {
        //   queryParams: {
        //     merchantId: res.merchant.id,
        //     paymentId: res.id,
        //   },
        // });
        //this.getNewCustomers();
        this.spinner.hide();
        const receipt = "/demand-receipt?merchantId=" + res.merchant.id + "&paymentId=" + res.id;
        window.open(receipt);
      }
    });
  }

  showPaymentReceipt(customer: Customers) {
    const receipt = "/payment/demand-receipt?merchantId=" + customer.merchant.id + "&paymentId=" + customer.id;
    window.open(receipt);
  }

  exportToExcel() {
    this.spinner.show();
    if (this.customerFilter.from != null) {
      // tslint:disable-next-line: max-line-length
      this.customerFilter.from = this.commonService.currentDateFormat(
        this.customerFilter.from.getFullYear(),
        this.customerFilter.from.getMonth(),
        this.customerFilter.from.getDate(),
        0,
        0,
        0,
        0
      );
    }
    if (this.customerFilter.to != null) {
      // tslint:disable-next-line: max-line-length
      this.customerFilter.to = this.commonService.currentDateFormat(
        this.customerFilter.to.getFullYear(),
        this.customerFilter.to.getMonth(),
        this.customerFilter.to.getDate(),
        23,
        59,
        0,
        0
      );
    }
    this.billerService.exportAdhocPaymentsToExcel(this.merchantId, this.customerFilter).subscribe(
      (res) => {
        if (res) {
          res.forEach((item) => {
            const paymentDate = new Date(item.paymentDate);
            item.paymentDate = this.commonService.formatDueDate(paymentDate);
          });
          const data = _.map(res, function (o) {
            return _.pick(o, "transactionId", "accountNumber", "firstName", "lastName", "email", "amount", "paymentDate");
          });
          const labels = "Transaction ref, Account Number, First Name, Last Name, Email, Amount Paid ($),Payment Date";
          const today = new Date();
          const date = this.commonService.formatDate(today);
          this.exportToExcelService.download(data, "Report_" + date, labels);
          this.toaster.success("The request data-sheet created successfully.", "");
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  //   if(this.adhocPayment){
  //   this.router.navigate(['/biller-support/demand-receipt'], {
  //     queryParams: {
  //       merchantId: customer.merchant.id,
  //       paymentId: customer.id
  //     }
  //   });
  // } else {
  //   this.router.navigate(['/report/demand-receipt'], {
  //     queryParams: {
  //       merchantId: customer.merchant.id,
  //       paymentId: customer.id
  //     }
  //   });
  // }
  navigate() {
    if (this.router.url === "/biller-support/new-customer") {
      this.IsSearching = true;
      this.openForm = false;
      this.adhocPayment = true;
      //this.showingPage = 10;
        this.paginationVM = <PaginationVM<Customers, CustomerFilter>>{
          pageNumber: 0,
          pageSize: 10,
          totalCount: 0,
          sortBy: "Id",
          sortType: "desc",
          records: [],
          filters: this.customerFilter,
        }
          this.customerFilter.to = this.commonService.currentDateFormat(
            this.customerFilter.to.getFullYear(),
            this.customerFilter.to.getMonth(),
            this.customerFilter.to.getDate(),
            23,
            59,
            0,
            0
          );
        this.paginationVM.filters = this.customerFilter;
      this.getNewCustomers();
    } else {
      this.location.back();
     }
  }

  numberOnly(event): boolean {
    let value = event.target.value;
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
     let current: string = value;
      const position = event.target.selectionStart;
      const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
      if (next && !String(next).match(this.regex)) {
       event.preventDefault();
      }

  }
}
