import { ToastrService } from "ngx-toastr";
import { CommonService } from "./../_core/common.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AutoLogoutService } from "app/_core/auto-logout.service";
import { User } from "app/_shared/model/user.model";
import decode from "jwt-decode";
import { NgxSpinnerService } from "ngx-spinner";
import { AccountService } from "app/account/account.service";
import { ConsumerDetails } from "app/_shared/model/consumer-details";
import { Consumer } from "app/account/_shared/model/consumer";
import { Merchant } from "app/account/_shared/model/merchant";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { PrivacyPalicyComponent } from "app/_shared/privacy-palicy/privacy-palicy.component";
import { TermsAndConditionsComponent } from "app/_shared/terms-and-conditions/terms-and-conditions.component";
import { MerchantUser } from "app/account/_shared/model/merchant-user";
declare var $: any;

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styles: []
})
export class MainComponent implements OnInit {
  userId: string;
  user: MerchantUser;
  sessionId: string;
  bsModalRef: BsModalRef;
  merchantUser: MerchantUser;
  logo: string;
  settingPages = ['/summary/bill-summary', '/utilities/list-utility','/usermanagement/users','/report/registered-auto-recurring','/report/setting/auto-payment-schedule','/report/auto-recurring','/report/setting/e-bill-subscription','/report/setting/ebill-subscription-list'];
  reportPages =['/registered-consumers','/master-card/mastercard-consumerlist','/report/optin-reports','/report/paid','/report/unpaid',
                '/payments/btx-payments', '/report/adhoc-payments', '/report/bankbillpay', '/master-card/mastercard-paymentlist',
                '/report/failed-payments', '/report/payment-report', '/report/ach-payments', '/report/ach-failed-payments', '/report/ach-success-payments','/report/auto-payment-schedule'];
  ActiveLink: number;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
    this.user = this.commonService.getMerchanUsertData();
    this.sessionId = this.commonService.getSession();
    this.merchantUser = new MerchantUser();
  }

  ngOnInit() {
    this.getbillerLogo();

    this.accountService.MessageUpdate.subscribe(isMessage => {
      if (isMessage) {
        this.toastr.success("password updated successfully!");
        this.accountService.updatePasswordMessage(false);
      }
    });
    if ($(window).innerWidth() <= 767) {
      $(".mobile-close").hide();
      $(".sidebar-left").removeClass("left-55");
      $(".sidebar-left").addClass("small-sidebar");
      $(".main-section").removeClass("open-sidebar");
      $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").hide();
      $(".side-menu ul li a").addClass("text-center");

      $(".btn-toggle-sidebar").click(function() {
        $(".mobile-close").show();
        $(".sidebar-left").toggleClass("left-55");
        $(".sidebar-left").toggleClass("small-sidebar");
        $(".main-section").toggleClass("open-sidebar");
        $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").toggle();
        $(".side-menu ul li a").removeClass("text-center");
      });
      $(".mobile-close").click(function() {
        $(this).hide();
        $(".sidebar-left").toggleClass("left-55");
        $(".sidebar-left").toggleClass("small-sidebar");
        $(".main-section").toggleClass("open-sidebar");
        $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").toggle();
        $(".small-sidebar .side-menu ul li a").addClass("text-center");
      });
    } else {
      $(".btn-toggle-sidebar").click(function() {
        $(".sidebar-left").toggleClass("left-55");
        $(".sidebar-left").toggleClass("small-sidebar");
        $(".main-section").toggleClass("open-sidebar");
        $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").toggle();
      });

      // $('button, #overlay').on('click', function(){
      // 	$('.dropdown-menu, #overlay').toggleClass('show')
      //   })
      $("p").click(function() {
        if (
          $(".dropdown")
            .find("a")
            .hasClass("active")
        ) {
          $(this).addClass("show");
          $(".drop-submenu").addClass("show");
        }
      });

      $(".custom-dropdown > a").click(function() {
        $(".drop-submenu").slideUp(200);
        if (
          $(this)
            .parent()
            .hasClass("active")
        ) {
          $(".custom-dropdown").removeClass("active");
          $(this)
            .parent()
            .removeClass("active");
        } else {
          $(".custom-dropdown").removeClass("active");
          $(this)
            .next(".drop-submenu")
            .slideDown(200);
            // .addClass("active");
          $(this)
            .parent()
            .addClass("active");
        }
      });
    }

    this.accountService.UserUpdate.subscribe(isReload => {
      if (isReload) {
        this.getUser();
        this.accountService.updateProfileOnNavbar(false);
      }
    });
    this.getUser();
    this.setActiveLink();
  }

  getUser() {
    this.spinner.show();
    this.accountService.GetMerchantUser(this.user.contactEmail).subscribe(res => {
      if (res) {
        this.merchantUser = res;
        this.spinner.hide();
      }
      err => {
        this.spinner.hide();
      };
    });
    this.spinner.hide();
  }

  logOut() {
    this.spinner.show();
    this.accountService.LogOut(this.sessionId).subscribe(res => {
      if (res) {
        sessionStorage.clear();
        this.router.navigate(["/"]);
      }
    });
    this.spinner.hide();
  }

  viewTermsAndConditions() {
    this.bsModalRef = this.modalService.show(TermsAndConditionsComponent, {
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Discard";

    // this.bsModalRef = this.modalService.show(TermsAndConditionsComponent){}
    // this.bsModalRef.content.closeBtnName = 'Discard'
  }

  viewPrivacyPolicy() {
    this.bsModalRef = this.modalService.show(PrivacyPalicyComponent, {
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Discard";
  }

  getbillerLogo(): void{
    const merchantId = this.commonService.getMerchantUserId();
    this.accountService.getBillerLogo(merchantId).subscribe(res => {
      this.logo = res.result;
    })
  }
  setActiveLink () {
    if(this.settingPages.includes(this.router.url)) {
      this.ActiveLink = 1;
    } else if (this.reportPages.includes(this.router.url)){
        this.ActiveLink = 2;
    } else {
        this.ActiveLink = 0;
    }
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        if(this.settingPages.includes(event.url)) {
          this.ActiveLink = 1;
        } else if (this.reportPages.includes(event.url)){
            this.ActiveLink = 2;
        } else {
            this.ActiveLink = 0;
        }
      }
    });
  }
}
