export class ReviewLogs {
    id: string;
    // existingAccountNumber: string;
    accountNumber: string;
    isAccountNumberFound: string;
    isMultipleInvoiceNumber: string;
    invoiceNumber: string;
    amount: number;
    paymentDate: Date;
    consumerName: string;
    status: string;
    mainDocId: string;
    isEdited: string
    customAccountNumber: string;
    constructor(){
        this.id = null;
        // this.existingAccountNumber = null;
        this.accountNumber = null;
        this.invoiceNumber = null;
        this.amount = null;
        this.paymentDate = null;
        this.consumerName = null;
        this.status = null;
        this.isAccountNumberFound = null;
        this.isMultipleInvoiceNumber = null;
        this.mainDocId = null;
        this.isEdited = null; 
        this.customAccountNumber = null;
    }
}

export class RPPSLogs {
    logs: ReviewLogs[];
    details: RPPSDetails;
    constructor(){
        this.logs = new Array<ReviewLogs>();
        this.details = new RPPSDetails();
    }
}

export class RPPSDetails {
    fileName: string;
    // status: string;
    constructor(){
        this.fileName = null;
        // this.status = null;
    }
}