import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "app/_core/common.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AccountService } from "app/account/account.service";
import { ToastrService } from "ngx-toastr";
import { MerchantUser } from "app/account/_shared/model/merchant-user";
import { BillerService } from "app/usermanagement/biller.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { TermsAndConditionsComponent } from 'app/_shared/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPalicyComponent } from "app/_shared/privacy-palicy/privacy-palicy.component";
declare var $: any;

@Component({
  selector: 'app-rpps-layout',
  templateUrl: './rpps-layout.component.html',
})
export class RppsLayoutComponent implements OnInit {
  user: MerchantUser;
  sessionId: string;
  merchantUser: MerchantUser;
  bsModalRef: BsModalRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private billerService: BillerService,
    private modalService: BsModalService
  ) {
    this.merchantUser = this.commonService.getMerchanUsertData();
    this.sessionId = this.commonService.getSession();
    this.user = new MerchantUser();
  }

  ngOnInit() {
    this.accountService.MessageUpdate.subscribe(isMessage => {
      if (isMessage) {
        this.toastr.success('password updated successfully!')
        this.accountService.updatePasswordMessage(false);
      }
    });


    if ($(window).innerWidth() <= 767) {
      $(".mobile-close").hide();
      $(".sidebar-left").removeClass("left-55");
      $(".sidebar-left").addClass("small-sidebar");
      $(".main-section").removeClass("open-sidebar");
      $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").hide();
      $(".side-menu ul li a").addClass("text-center");

      $(".btn-toggle-sidebar").click(function() {
        $(".mobile-close").show();
        $(".sidebar-left").toggleClass("left-55");
        $(".sidebar-left").toggleClass("small-sidebar");
        $(".main-section").toggleClass("open-sidebar");
        $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").toggle();
        $(".side-menu ul li a").removeClass("text-center");
      });
      $(".mobile-close").click(function() {
        $(this).hide();
        $(".sidebar-left").toggleClass("left-55");
        $(".sidebar-left").toggleClass("small-sidebar");
        $(".main-section").toggleClass("open-sidebar");
        $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").toggle();
        $(".small-sidebar .side-menu ul li a").addClass("text-center");
      });
    } else {
      $(".btn-toggle-sidebar").click(function() {
        $(".sidebar-left").toggleClass("left-55");
        $(".sidebar-left").toggleClass("small-sidebar");
        $(".main-section").toggleClass("open-sidebar");
        $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").toggle();
      });

      // $('button, #overlay').on('click', function(){
      // 	$('.dropdown-menu, #overlay').toggleClass('show')
      //   })
    }
    this.getUser();
  }

  getUser() {
    this.spinner.show();
    this.billerService.GetMerchantUser(this.merchantUser.contactEmail).subscribe(res => {
      if (res) {
        this.user = res;
        this.spinner.hide();
      }
      err => {
        this.spinner.hide();
      };
    });
  }

  logOut() {
    this.spinner.show();
    this.accountService.LogOut(this.sessionId).subscribe(res => {
      if (res) {
        sessionStorage.clear();
        this.router.navigate(["/"]);
      }
    });
    this.spinner.hide();
  }

  viewTermsAndConditions() {
    this.bsModalRef = this.modalService.show(TermsAndConditionsComponent, {
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Discard";

    // this.bsModalRef = this.modalService.show(TermsAndConditionsComponent){}
    // this.bsModalRef.content.closeBtnName = 'Discard'
  }

  viewPrivacyPolicy() {
		this.bsModalRef = this.modalService.show(PrivacyPalicyComponent, {
			class: 'modal-lg'
		});
		this.bsModalRef.content.closeBtnName = 'Discard';
	}
}
