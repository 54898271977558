export class BillFilter {
  public utilityId: string;
  public from: string;
  public to: string;
  public status: string;

  constructor() {
    this.utilityId = null;
    this.from = null;
    this.to = null;
    this.status = null;
  }

}
export class MerchantBillFilter {
  public utilityId: string;
  public billCycle: string;
  public billDate: string;
  public consumerName: string;
  public accountNumer: string;
  public billFrom: Date;
  public billTo: Date;
  public publish: string;
  public billStatus: string;
  constructor() {
    this.utilityId = null;
    this.billCycle = null;
    this.billDate = null;
    this.consumerName = null;
    this.accountNumer = null;
    this.billFrom = new Date;
    this.billTo = new Date;
    this.publish = null;
    this.billStatus = null;
  }

}
export class MerchantPaymentFilter {
  public name: string;
  public utilityId: string;
  public billCycle: string[];
  //public consumerName: string;
  public accountNumer: string;
  public from: Date;
  public to: Date;
  public status: string;
  public paymentMode: string;
  public paymentChannel: string;
  public cardServiceProvider: string
  public cardType: string
  public paymentStatus : string;

  constructor() {
    this.name = null;
    this.utilityId = null;
    this.billCycle = new Array<string>();
    //this.consumerName = null;
    this.accountNumer = null;
    this.from = null;
    this.to = null;
    this.status = null;
    this.paymentMode = null;
    this.paymentChannel = null;
    this.cardServiceProvider = null;
    this.cardType = null;
    this.paymentStatus = null;
  }
}

export class MerchantAutoPaymentFilter {
  public utilityId: string;
  public accountNumber: string;
  public scheduledFrom: Date;
  public scheduledTo: Date;
  public paymentMode: string;
  public status: string;


  constructor() {
    this.utilityId = null;
    this.accountNumber = null;
    this.scheduledFrom = null;
    this.scheduledTo = null;
    this.paymentMode = '';
    this.status='';
  }

}
