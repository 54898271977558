import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilitiesService } from '../utilities.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'app/_core/common.service';
import { ToastrService } from 'ngx-toastr';
import { SentMailCount } from '../_shared/model/sent-mail-count';
import { SentMailFilter } from '../_shared/model/sent-mail-filter';
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { SentMails } from '../_shared/model/sent-mails';
import { MailBySubject } from '../_shared/model/mail-by-subject';

@Component({
  selector: 'app-modal-mail-list',
  templateUrl: './modal-mail-list.component.html',
  styleUrls: ['./modal-mail-list.component.css']
})
export class ModalMailListComponent implements OnInit {
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  pageNumber: number;
  paginationVM: PaginationVM<MailBySubject, SentMailFilter>;
  sentMailsList: MailBySubject[];
  sentMailFilter: SentMailFilter;
  sentMailsCountList: MailBySubject[];
  merchantId: string;
  utilityId: string;
  bunchId: string;

  constructor(public bsModalRef: BsModalRef, private router: Router, private utilitiesService: UtilitiesService,
    private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute, private commonService: CommonService,
    private toastr: ToastrService) {
    this.pageNumber = 1;
    this.sentMailFilter = new SentMailFilter();
    this.sentMailsList = new Array<MailBySubject>();
    this.sentMailsCountList = new Array<MailBySubject>();
  }

  ngOnInit() {
    this.sentMailFilter.utilityId = this.utilityId;
    this.paginationVM = <PaginationVM<MailBySubject, SentMailFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: 'Id',
      sortType: 'desc',
      records: this.sentMailsCountList,
      filters: this.sentMailFilter
    };
    this.getSentMails();
  }

  close(modal) {
    modal.hide();
  }

  /* get receipients list on mail subject */
  getSentMails() {
    this.spinner.show();
    this.utilitiesService.getSentMailsBySubject(this.merchantId, this.utilityId,this.bunchId, this.paginationVM).subscribe((res) => {
      if (res) {
        this.paginationVM = res;
        this.sentMailsCountList = new Array<MailBySubject>();
        this.sentMailsCountList = this.paginationVM.records;
        if (this.increase) {
          this.showingPage = this.showingPage + this.paginationVM.records.length;
        } else if (this.decrease) {
          this.showingPage = this.showingPage - this.oldPageLength;
        } else {
          this.showingPage = this.paginationVM.records.length;
        }
        this.oldPageLength = this.paginationVM.records.length;
        this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
        this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
        this.increase = false;
        this.decrease = false;
        this.spinner.hide();
      }
      (err) => {
        this.spinner.hide();
      };
    });
  }

  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getSentMails();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getSentMails();
    }
  }
}
