import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BillsService } from "app/bills/bills.service";
import { PublishService } from "../publish.service";
import { BillSummary } from "../_shared/model/bill-summary";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BillSummaryEmailsVM } from "../_shared/model/bill-summary-emails-vm";
import { Constant } from "app/_shared/constant";

@Component({
  selector: "app-bill-notification-summary",
  templateUrl: "./bill-notification-summary.component.html",
  styleUrls: ["./bill-notification-summary.component.css"],
})
export class BillNotificationSummaryComponent implements OnInit {
  merchantId: string;
  utilityId: string;
  billId: string;
  utilityName: string;
  consumerName: string;
  accountNo: string;
  billDate: Date;
  billNotificationSummary: BillSummary[];
  billNotificationSummaryEmail: string[];
  emailsObject: BillSummary;
  emailString: string;
  comma_seperate_emails = Constant.COMMA_SEPERATE_EMAILS;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private billService: BillsService,
    private publishBillService: PublishService
  ) {
    this.billNotificationSummary = new Array<BillSummary>();
    this.billNotificationSummaryEmail = new Array<string>();
    this.emailsObject = new BillSummary();
    this.emailString = null;
  }

  ngOnInit() {
    this.loadBillSummary();
  }

  close(modal) {
    modal.hide();
    this.emailString = null;
  }

  //** load specific bill related summary */
  loadBillSummary() {
    this.spinner.show();
    this.publishBillService.LoadBillSummary(this.merchantId, this.utilityId, this.billId, this.accountNo).subscribe((res) => {
      if (res) {
        this.billNotificationSummary = res.billSummary;
        this.billNotificationSummaryEmail = res.registeredEmails;
        this.spinner.hide();
      }
      (err) => {
        this.spinner.hide();
      };
    });
  }

  hoverObject(summary: BillSummary) {
    this.spinner.show();
    this.emailsObject = new BillSummary();
    this.emailsObject = summary;
    this.emailsObject.emails.map((item) => {
      item.opensCount = item.opensCount == undefined || null ? 0 : item.opensCount;
    });
    this.spinner.hide();
  }

  //** resend email against that bill */
  ResendEmail() {
    this.spinner.show();
    this.publishBillService.ResendEmail(this.billId, this.merchantId, this.utilityId, this.accountNo, this.emailString).subscribe((res) => {
      if (res) {
        this.spinner.hide();
        this.emailString = null;
        if (res.result === "Mail sent successfully") {
          this.loadBillSummary();
          this.toastr.success(res.result, "", {
            timeOut: 3000,
          });
        } else {
          this.toastr.error(res.result, "", {
            timeOut: 3000,
          });
        }
      }
      (err) => {
        this.spinner.hide();
      };
    });
  }
}
