import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CommonService } from "app/_core/common.service";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { concat, Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from "rxjs/operators";
import { RppsService } from "../rpps.service";
import { AccountWithName } from "../_shared/account-with-name";
import { InvoiceWithAmount } from "../_shared/invoice-with-amount";
import { ProcessRPPS } from "../_shared/process-rpps";
import { ReviewLogs } from "../_shared/review-logs";
import { RppsSummary, RppsSummaryFilter } from "../_shared/rpps-summary";

@Component({
  selector: "app-review-records",
  templateUrl: "./review-records.component.html",
  styleUrls: ["./review-records.component.css"],
})
export class ReviewRecordsComponent implements OnInit {
  toAccountNumbers$: Observable<AccountWithName[]>;
  toAccountInput$ = new Subject<string>();
  toAccountsLoading = false;

  toInvoiceNumbers$: Observable<InvoiceWithAmount[]>;
  toInvoiceInput$ = new Subject<string>();
  toInvoicesLoading = false;

  private unsubscribeAccount$ = new Subject<void>();
  private unsubscribeInvoice$ = new Subject<void>();
  logs: ReviewLogs[];
  merchantId: string;
  rppsid: any;
  isMultipleInovice: string;
  selectedId: string;
  isEdit: boolean = false;
  accountNumber: string;
  modalData: ReviewLogs;
  fileName: string;
  billTypeId: string;
  existingData: { accountNumber: string; invoiceNumber: string; id: string };

  accounts: string;
  invoices: string;
  merchantUserName: string;
  isSubmitDisabled: boolean = false;
  backUrl: boolean = false;
  enteredAccountNumber: string;
  enteredInvoiceNumber: string;
  deleteItem: ReviewLogs;
  // showAddAccountNumber: boolean = false;
  constructor(
    private rppsService: RppsService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.logs = new Array<ReviewLogs>();
    this.deleteItem = new ReviewLogs();
    this.merchantId = this.commonService.getMerchantUserId();
    this.merchantUserName = this.commonService.getMerchantUserName();
    this.modalData = new ReviewLogs();
    this.existingData = { accountNumber: null, invoiceNumber: null, id: null };
  }

  ngOnInit() {
    if (this.router.url.includes("/report/review")) {
      this.backUrl = true;
    }

    this.activatedRoute.queryParams
      .pipe(
        tap((queryParams: Params) => {
          this.rppsid = queryParams["rppsid"];
          this.billTypeId = queryParams["billTypeId"];
        }),
        takeUntil(this.unsubscribeAccount$)
      )
      .subscribe(
        () => {},
        (err) => {}
      );
    this.getRppsReviewLogs();

    this.toAccountNumbers$ = concat(
      of([]),
      this.toAccountInput$.pipe(
        debounceTime(10),
        distinctUntilChanged(),
        tap(() => (this.toAccountsLoading = true)),
        switchMap((term) => {
          if (term.length > 3) {
            return this.rppsService.getAccountNumbers(this.merchantId, this.billTypeId, term).pipe(
              catchError(() => of([])),
              tap(() => (this.toAccountsLoading = false)),
              takeUntil(this.unsubscribeAccount$)
            );
          }
        })
      )
    );

    this.toInvoiceNumbers$ = concat(
      of([]),
      this.toInvoiceInput$.pipe(
        debounceTime(10),
        distinctUntilChanged(),
        tap(() => (this.toInvoicesLoading = true)),
        switchMap((term) =>
          this.rppsService.getInvoiceNumbers(this.merchantId, this.billTypeId, this.modalData.accountNumber, term).pipe(
            catchError(() => of([])),
            tap(() => (this.toInvoicesLoading = false)),
            takeUntil(this.unsubscribeInvoice$)
          )
        )
      )
    );
  }

  getAccountNumbers() {
    this.toAccountNumbers$ = concat(
      of([]),
      this.toAccountInput$.pipe(
        debounceTime(10),
        distinctUntilChanged(),
        tap(() => (this.toAccountsLoading = true)),
        switchMap((term) =>
          this.rppsService.getAccountNumbers(this.merchantId, this.billTypeId, term).pipe(
            catchError(() => of([])),
            tap(() => (this.toAccountsLoading = false)),
            takeUntil(this.unsubscribeAccount$)
          )
        )
      )
    );
  }

  getInvoiceNumber() {
    this.toInvoiceNumbers$ = concat(
      of([]),
      this.toInvoiceInput$.pipe(
        debounceTime(10),
        distinctUntilChanged(),
        tap(() => (this.toInvoicesLoading = true)),
        switchMap((term) =>
          this.rppsService.getInvoiceNumbers(this.merchantId, this.billTypeId, this.modalData.accountNumber, term).pipe(
            catchError(() => of([])),
            tap(() => (this.toInvoicesLoading = false)),
            takeUntil(this.unsubscribeInvoice$)
          )
        )
      )
    );
  }

  getRppsReviewLogs(): void {
    this.isSubmitDisabled = false;
    this.spinner.show();
    this.rppsService.review(this.merchantId, this.billTypeId, this.rppsid).subscribe((res) => {
      if (res) {
        this.fileName = res.details.fileName;
        this.logs = res.logs;
        // this.logs.map((ele) => {
        //     ele.isEdited  = 'no';
        // });
        this.spinner.hide();
      }
      (err) => {
        this.spinner.hide();
      };
    });
  }

  edit(item: ReviewLogs, modal): void {
    this.modalData = new ReviewLogs();
    modal.show();
    this.selectedId = item.id;
    this.modalData = item;
    this.existingData = { accountNumber: item.accountNumber, invoiceNumber: item.invoiceNumber, id: item.id };
    this.invoices = null;
    this.accounts = null;
    this.enteredAccountNumber = null;
    this.getAccountNumbers();
    this.getInvoiceNumber();
  }

  // onFocusOutEvent(item: ReviewLogs, accountNumber: string) {
  //   this.logs.map(ele => {
  //     if (item.id === ele.id) {
  //       ele.accountNumber = accountNumber;
  //       ele.isAccountNumberFound = 'yes';
  //     }
  //   });
  // }

  update(item: ReviewLogs, account: string, customAccountNumber: string, customInvoiceNumber: string, invoice: string, modal): void {
    modal.hide();
    this.spinner.show();
    this.logs.map((ele) => {
      if (item.id === ele.id) {
        //   if ((account !== null && account !== undefined && item.accountNumber !== account) ||
        //   (invoice !== null && invoice !== undefined && item.invoiceNumber !== invoice) ||
        //   ((account !== null && account !== undefined && item.accountNumber === account) &&
        //   (invoice !== null && invoice !== undefined && item.invoiceNumber === invoice)) &&
        //   (account !== null && invoice !== undefined && account) ||
        //   (invoice !== null && invoice !== undefined && invoice)
        //   ) {
        //    ele.isEdited = 'yes';
        //  } else {
        //    ele.isEdited = 'no';
        //  }

        //  ele.existingAccountNumber = account !== null && account !== undefined ? account : item.accountNumber;
        if (
          (customAccountNumber !== null && customAccountNumber !== undefined) ||
          (customInvoiceNumber !== null && customInvoiceNumber !== undefined)
        ) {
          ele.accountNumber = customAccountNumber !== null && customAccountNumber !== undefined ? customAccountNumber : item.accountNumber;
          ele.invoiceNumber = customInvoiceNumber !== null && customInvoiceNumber !== undefined ? customInvoiceNumber : item.invoiceNumber;
        } else if (this.accounts !== null && this.accounts !== undefined) {
          ele.accountNumber = account !== null && account !== undefined ? account : item.accountNumber;
          ele.invoiceNumber = invoice !== null && invoice !== undefined ? invoice : null;
        } else {
          ele.accountNumber = account !== null && account !== undefined ? account : item.accountNumber;
          ele.invoiceNumber = invoice !== null && invoice !== undefined ? invoice : item.invoiceNumber;
        }
      }
      this.spinner.hide();
    });
  }

  closeModal(modal): void {
    modal.hide();
    this.modalData = new ReviewLogs();
    this.isSubmitDisabled = false;
    this.getRppsReviewLogs();
  }

  storePRocessesData(item: ReviewLogs[]): void {
    const data = new ProcessRPPS();
    data.merchantId = this.merchantId;
    data.utilityId = this.billTypeId;
    data.mainDocId = this.logs[0].mainDocId;
    data.logs = item;
    data.merchantUserName = this.merchantUserName;
    this.spinner.show();
    this.rppsService.storeProcessedData(data).subscribe((res) => {
      if (res) {
        this.toastr.success("Data submitted successfully");
        this.router.navigate(["/rpps"]);
        this.spinner.hide();
      }
      (err) => {
        this.spinner.hide();
      };
    });
  }

  changeEventAccount(event: any, item: any) {
    this.modalData.accountNumber = event !== null && event !== undefined ? event.accountNumber : item.accountNumber;
    this.invoices = null;
    this.getInvoiceNumber();
    this.enteredAccountNumber = null;
    this.logs.map((ele) => {
      if (item.id === ele.id) {
        if (event !== undefined && event !== null) {
          ele.isEdited = "yes";
          this.isSubmitDisabled = true;
        } else {
          ele.isEdited = "no";
          this.isSubmitDisabled = false;
        }
      }
      this.spinner.hide();
    });
  }

  changeEventInvoice(event: any, item: any) {
    // this.modalData.accountNumber = event.accountNumber;
    // this.invoices = null;
    // this.getInvoiceNumber();
    this.enteredInvoiceNumber = null;
    this.logs.map((ele) => {
      if (item.id === ele.id) {
        if (event !== undefined && event !== null) {
          ele.isEdited = "yes";
          // this.isSubmitDisabled = true;
        } else {
          ele.isEdited = "no";
          //  this.isSubmitDisabled = false;
        }
      }
      this.spinner.hide();
    });
  }

  customAccountChangeEvent(event: any, item: any) {
    this.accounts = null;
    this.getAccountNumbers();
    this.invoices = null;
    this.getInvoiceNumber();
    this.modalData.accountNumber = null;
    this.logs.map((ele) => {
      if (item.id === ele.id) {
        if (event !== undefined && event !== null) {
          ele.isEdited = "yes";
          ele.customAccountNumber = event;
          this.isSubmitDisabled = true;
        } else {
          ele.isEdited = "no";
          this.isSubmitDisabled = false;
        }
      }
      this.spinner.hide();
    });
  }

  customInvoiceChangeEvent(event: any, item: any) {
    // this.accounts = null;
    // this.getAccountNumbers();
    this.invoices = null;
    this.getInvoiceNumber();
    this.logs.map((ele) => {
      if (item.id === ele.id) {
        if (event !== undefined && event !== null) {
          ele.isEdited = "yes";
          ele.customAccountNumber = event;
          this.isSubmitDisabled = true;
        } else {
          ele.isEdited = "no";
          this.isSubmitDisabled = false;
        }
      }
      this.spinner.hide();
    });
  }

  openDeleteModal(item: ReviewLogs): void {
     this.deleteItem = item;
  }

  delete(item: ReviewLogs): void {
    debugger;
    const data = {
      MerchantId: this.merchantId,
      UtilityId: this.billTypeId,
      DocId: item.mainDocId,
      SubDocId: item.id,
    };
    this.rppsService.delete(data).subscribe((res) => {
      if (res) {
        this.toastr.success("data deleted successfully");
        this.getRppsReviewLogs();
        this.spinner.hide();
      }
      (err) => {
        this.spinner.hide();
      };
    });
  }
}
