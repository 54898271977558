import { AccountService } from "./../account.service";
import { ChangePassword } from "./../../_shared/model/change-password";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { MaxLengthConstants, Constant } from "app/_shared/constant";
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
  changePassword: ChangePassword;
  passwordMismatch: boolean;
  modalRef: BsModalRef;
  public loading = false;

  maxlengthPassword = MaxLengthConstants.Password;
  passwordPattern = Constant.PASSWORD_REGEXP;
  billerChangePassword: boolean;
  differentPassword: boolean;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) {
    this.changePassword = new ChangePassword();
    this.passwordMismatch = true;
    this.billerChangePassword = false;
    this.differentPassword = false;
  }

  ngOnInit() {
    if (this.router.url === "/biller-support/change-password") {
      this.billerChangePassword = true;
    }
  }

  /*Validate Password*/
  validatePassword(change) {
    if (change && change.password !== change.confirmPassword && change.confirmPassword !== change.password) {
      this.passwordMismatch = false;
    } else {
      this.passwordMismatch = true;
    }

    if (
      this.changePassword.oldPassword === this.changePassword.password ||
      this.changePassword.password === this.changePassword.oldPassword
    ) {
      this.differentPassword = false;
    } else {
      this.differentPassword = true;
    }
  }

  ChangePassword(isValid: boolean) {
    if (isValid && this.passwordMismatch && this.differentPassword) {
      this.spinner.show();
      this.accountService.ChangePassword(this.changePassword).subscribe(
        res => {
          if (res) {
            this.accountService.updatePasswordMessage(true);
            this.billerChangePassword === true ? this.router.navigate(["/biller-support"]) : this.router.navigate(["/dashboard"]);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    } else {
    }
  }
}
