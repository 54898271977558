import { AdminLayoutComponent } from './admin-layout.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { SharedModule } from 'app/_shared/shared.module';
import { AccountLayoutComponent } from './account.layout.component';
import { EmptyLayoutComponent } from './empty-layout.component';
import { TermsAndConditionsComponent } from 'app/_shared/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPalicyComponent } from 'app/_shared/privacy-palicy/privacy-palicy.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EmptyWizardComponent } from './empty-wizard.component';
import { BillerSupportLayoutComponent } from './biller-support-layout.component';
import { RppsLayoutComponent } from './rpps-layout.component';

@NgModule({
  declarations: [AccountLayoutComponent,AdminLayoutComponent, EmptyLayoutComponent, EmptyWizardComponent, BillerSupportLayoutComponent, RppsLayoutComponent],
  imports: [CommonModule, LayoutsRoutingModule, FormsModule, SharedModule,ModalModule.forRoot()],
  exports: [AccountLayoutComponent],
  entryComponents: [TermsAndConditionsComponent,PrivacyPalicyComponent],
})
export class LayoutsModule {}
