import { Component, OnInit } from '@angular/core';
import { AccountService } from 'app/account/account.service';
import { User } from 'app/_shared/model/user.model';
import { NgForm } from '@angular/forms';
import { Login } from 'app/_shared/model/login.model';
import { AutoLogoutService } from 'app/_core/auto-logout.service';
import { MaxLengthConstants, Constant } from 'app/_shared/constant';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TermsAndConditionsComponent } from 'app/_shared/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPalicyComponent } from 'app/_shared/privacy-palicy/privacy-palicy.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContactUs } from 'app/_shared/model/contact-us';
import { NewsList } from 'app/_shared/model/news-list';
declare var $: any;
@Component({
	selector: 'app-static',
	templateUrl: './static.component.html',
	styles: []
})
export class StaticComponent implements OnInit {
	user: User;
	data: Login;
	public loading = false;
	linkName: string;
	contactUs: ContactUs;

	userNameLength = MaxLengthConstants.UserName;
	userNamePattern = Constant.USERNAME;
	passwordLength = MaxLengthConstants.Password;
	passwordPattern = Constant.PASSWORD_REGEXP;
	emailLength = MaxLengthConstants.Email;
	emailPattern = Constant.EMAIL_REGEXP;
	phonePattern = Constant.PHONE_REGEXP;
	phoneLength = MaxLengthConstants.Phone;
	bsModalRef: BsModalRef;
	newsList: NewsList[];
	itemList: NewsList;
	list: NewsList[];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private accountService: AccountService,
		private autoLogoutService: AutoLogoutService,
		private modalService: BsModalService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
	) {
		this.user = new User();
		this.data = new Login();
		this.contactUs = new ContactUs();
		this.itemList = new NewsList()
     this.newsList = new Array<NewsList>();

		autoLogoutService.eventCallback$.subscribe((data) => {
			this.logOut();
		});
	}

	ngOnInit() {
		this.news();

		//tooltip
		$(function () {
			$('[data-toggle="tooltip"]').tooltip();
		});

		// $('#newsTicker1').breakingNews();
		$('#header-slider.owl-carousel').owlCarousel({
			loop: true,
			dots: true,
			nav: false,
			items: 1,
			animateOut: 'fadeOut',
			autoplay: true
		});

		$('#utilities-slider.owl-carousel').owlCarousel({
			loop: true,
			dots: false,
			nav: true,
			margin: 20,
			autoplay: false,
			center: true,
			navText: ["<i class='fas fa-angle-left'></i>", "<i class='fas fa-angle-right'></i>"],
			responsive: {
				0: {
					items: 1
				},
				576: {
					items: 3
				},
				992: {
					items: 3
				},
				1200: {
					items: 5
				}
			}
		});

		$('#office-slider.owl-carousel').owlCarousel({
			loop: true,
			dots: false,
			nav: true,
			margin: 20,
			autoplay: true,
			navText: ["<i class='fas fa-angle-left'></i>", "<i class='fas fa-angle-right'></i>"],
			responsive: {
				0: {
					items: 1
				},
				576: {
					items: 2
				},
				992: {
					items: 3
				},
				1200: {
					items: 4
				}
			}
		});

		this.router.events.subscribe((s) => {
			if (s instanceof NavigationEnd) {
				const tree = this.router.parseUrl(this.router.url);
				if (tree.fragment) {
					const element = document.querySelector('#' + tree.fragment);
					if (element) {
						element.scrollIntoView(true);
					}
				}
			}
		});
	}

	ngAfterViewInit() {
		$('#newsTicker1').breakingNews();
	  }
	// login(form: NgForm, user: User) {
	// 	if (form.valid) {
	// 		this.loading = true;
	// 		this.accountService.login(this.user.userName, this.user.password).subscribe(
	// 			(res) => {
	// 				this.data = res;
	// 				if (this.data) {
	// 					this.router.navigate([ '/search/search-criteria' ]);
	// 					sessionStorage.setItem('currentUser', JSON.stringify(this.data.result));
	// 					sessionStorage.setItem('AccessToken', this.data.token.result.value);
	// 					this.loading = false;
	// 				}
	// 			},
	// 			(err) => {
	// 				this.loading = false;
	// 				this.toast.error('Provide correct username and password', 'Error');
	// 			}
	// 		);
	// 	}
	// }

	logOut() {
		sessionStorage.clear();
		this.router.navigate(['']);
	}

	forgotPassword() {
		this.router.navigate(['/account/forgot-password']);
	}

	onAnchorClick() {
		this.route.fragment.subscribe((f) => {
			this.linkName = f;
			const element = document.querySelector('#' + f);
			if (element) {
				element.scrollIntoView(true);
			}
		});
	}

	AddContacts(isValid: boolean) {
		if (isValid) {
		}
	}

	viewTermsAndConditions() {

		this.bsModalRef = this.modalService.show(TermsAndConditionsComponent, {
			class: 'modal-lg'
		});
		this.bsModalRef.content.closeBtnName = 'Discard';


		// this.bsModalRef = this.modalService.show(TermsAndConditionsComponent){}
		// this.bsModalRef.content.closeBtnName = 'Discard'
	}

	viewPrivacyPolicy() {

		this.bsModalRef = this.modalService.show(PrivacyPalicyComponent, {
			class: 'modal-lg'
		});
		this.bsModalRef.content.closeBtnName = 'Discard';
	}

	sendQuery(form: NgForm) {
		if (form.valid) {
			this.spinner.show();
			this.accountService.postContact(this.contactUs).subscribe(
				(res) => {
					this.spinner.hide();
					if (res != null) {
						this.toastr.success('Your query has been submitted successfully.', 'Thank you!', {
							timeOut: 3000
						});
						// setTimeout(() => {

						// 	}, 3000);
					}
					form.resetForm();
				},
				(err) => {
					this.toastr.error('unable to contact.', '', {
						timeOut: 4000
					});
					this.spinner.hide();
				}
			);
		}
	}

	cancel(form: NgForm) {

		form.resetForm();
	}

	news() {
		var xml = new XMLHttpRequest();
		xml.open('GET', '../assets/data/news.xml', false);
		xml.send();
		var xmlData = xml.responseXML;
		if (xmlData != null) {
			xmlData = new DOMParser().parseFromString(xml.responseText, 'text/xml');
		}
		var main = xmlData.getElementsByTagName('main');
		var news = main[0].getElementsByTagName('news')[0].children;
		for (let index = 0; index < news.length; index++) {
			this.itemList = new NewsList()
			this.itemList.news = news[index].innerHTML;
			this.newsList.push(this.itemList);
		}
	}
}
