import { ItemId } from './item-id';

export class MerchantBill {
    public billId: string;
    public billCycle: string;
    public utility: ItemId;
    public accountNumber: string;
    public billUserName: string;
    public billDate: string;
    public dueDate: string;
    public dueAmount: number;
    public status: string;
    public merchant: ItemId;
    public utilityLogo: string;
    public templateVersion: string;
    public isPublished: boolean;
    public isAllBillsPublished: string;
    public pdfName: string;
    public containerName: string;
    public utilityName: string;
    constructor() {
        this.billId = null;
        this.billCycle = null;
        this.utility = null;
        this.accountNumber = null;
        this.billUserName = null;
        this.dueDate = null;
        this.dueAmount = null;
        this.status = null;
        this.merchant = new ItemId();
        this.utilityLogo = null;
        this.templateVersion = null;
        this.isPublished = false;
        this.isAllBillsPublished = null;
        this.pdfName = null;
        this.containerName = null;
        this.utilityName = null;
    }
}
