import { Component, OnInit, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { BillViewModel } from '../_shared/model/bill-view-model';
import { LatestBill } from '../_shared/model/latest-bill';
import * as c3 from 'c3';
import * as d3 from 'd3';
@Component({
	selector: 'app-bill-chart',
	templateUrl: './bill-chart.component.html',
	styleUrls: [ './bill-chart.component.css' ]
})
export class BillChartComponent implements OnInit {
	@Input('chartId') chartId: string;
	@Input('chartData') chartData: any;
	@Input('type') type: string;
	@Input('height') height: string;
	@Input('width') width: string;
	data: number[];
	labels: string[];
	chartBill: LatestBill;
	bill: any;
	amountChartData: any[];

	constructor() {}

	ngOnInit() {}

	/* get data on change*/
	ngOnChanges(changes: SimpleChanges) {
		const data: SimpleChange = changes.chartData;
		if (this.type === "line") {
			this.bill = data.currentValue;
			if (this.bill.payments !== null) {
				this.amountChartData = this.generateChartData(this.bill.payments, 'Amount');
				setTimeout(() => {
					this.createChart(this.amountChartData, Object(this.chartId), 'Amount', '#fa9600');
				}, 0);
			}
		}
		else {
			this.bill = data.currentValue;
			if (this.bill.graphList !== null) {
			  this.amountChartData = this.generateChartData(this.bill.graphList, "Gallons");
			  setTimeout(() => {
				this.createChart(this.amountChartData, Object(this.chartId), "Gallons", "#46C2E2");
			  }, 0);
			}
		  }
		
	}

	/* binding to graph */
	createChart(chartData, id, chartName, color) {
		var chart = c3.generate({
			bindto: '#' + id,
			size: {
				height: this.height,
				width: this.width
			},
			data: {
				x: 'x',
				columns: chartData,
				type: this.type
			},
			point: {
				r: 6 
			},
			color: {
				pattern: [ color ]
			},
			axis: {
				x: {
					type: 'category',
					label: {
						position: 'outer-middle'
					}
				},
				y: {
					label: {
						text: chartName,
						position: 'outer-left'
					},
					padding: {
						top: 40,
					}
				}
			}
		});
	}

	/* generate chart */
	generateChartData(chartData, chartValue) {
		let amounts;
		let months;

		if (chartData.length !== 0 && chartData !== null && chartData !== undefined) {
			const chartDataChunks = [];
			initChartData(chartValue);

			let amount = Object.values(chartData).map((value: number) => value);
			let month = Object.keys(chartData).map((key: string) => key.toUpperCase());
			amounts = amounts.concat(amount);
			months = months.concat(month);
			if (months !== null && months.length !== 0) {
				chartDataChunks.push(months);
			}
			if (amounts !== null && amounts.length !== 0) {
				chartDataChunks.push(amounts);
			}
			return chartDataChunks;
		}

		function initChartData(value) {
			months = [ 'x' ];
			amounts = [ value ];
		}
	}
}
