import { ReviewLogs } from "./review-logs";

export class ProcessRPPS {
    merchantId: string;
    utilityId: string;
    mainDocId: string
    logs: ReviewLogs[];
    merchantUserName: string;
   // isEdited: string
    constructor(){
        this.merchantId = null;
        this.utilityId = null;
        this.mainDocId = null;
        this.logs = new Array<ReviewLogs>();
        this.merchantUserName = null;
       // this.isEdited = null;
    }
}
