import { ItemId } from 'app/_shared/model/item-id';

export class NewCustomer {
  public accountNumber: string;
  public prefix: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public amount: number;
  public intellipayResponse: string;
  public merchant: ItemId;
  public utility: ItemId;
  public reason: string;
  constructor() {
    this.accountNumber = null;
    this.prefix = null;
    this.firstName = null;
    this.lastName = null;
    this.email = null;
    this.amount = null;
    this.intellipayResponse = null;
    this.merchant = new ItemId();
    this.utility = new ItemId();
    this.reason = null;
  }
}

