import { UtilityMessage } from './_shared/model/utility-message';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'app/_core/data.service';
import { MerchantUtilitySales } from 'app/dashboard/_shared/model/merchant-utility-sales';
import { BroadcastMessages } from './_shared/model/broadcast-messages';
import { BroadcastMessageFilter } from './_shared/model/broadcast-message-filter';
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { UpdateBroadcast } from './_shared/model/update-broadcast';
import { UpdateUtilityMessage } from './_shared/model/update-utility-message';
import { ItemId } from 'app/_shared/model/item-id';
import { SendMail } from './_shared/model/send-mail';
import { EmailItems } from './_shared/model/email-items';
import { SentMails } from './_shared/model/sent-mails';
import { SentMailFilter } from './_shared/model/sent-mail-filter';
import { SentMailCount } from './_shared/model/sent-mail-count';
import { MailBySubject } from './_shared/model/mail-by-subject';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor(private dataService: DataService) { }
  merchantUtility(merchantId: string): Observable<MerchantUtilitySales[]> {
    return this.dataService.get<MerchantUtilitySales[]>(`merchant/Utilities/getMerchantUtilities/${merchantId}`);
  }

  AddUtilityMessage(message: UtilityMessage, utilityId: string): Observable<UtilityMessage> {
    // return this.dataService.post<UtilityMessage,UtilityMessage>('merchant/Utilities/', message);
    const methodName = `merchant/Utilities?utilityId=${utilityId}`;
    return this.dataService.post(methodName, message);
  }

  getMessageList(merchantId: string, utilityId: string): Observable<UtilityMessage[]> {
    return this.dataService.get<UtilityMessage[]>(`merchant/Utilities/getMessageList/${merchantId}/${utilityId}`);
  }

  DeleteMessages(messageId: string, merchantId: string, utilityId: string): Observable<boolean> {
    return this.dataService.delete(`merchant/Utilities/deleteMessages/${messageId}/${merchantId}/${utilityId}`);
  }

  UpdateUtilityMessage(merchantId: string, message: UpdateUtilityMessage, utilityId: string): Observable<UtilityMessage> {
    // return this.dataService.post<UtilityMessage, UtilityMessage>('merchant/Utilities/update', message);
    const methodName = `merchant/Utilities/update?merchantId=${merchantId}&utilityId=${utilityId}`;
    return this.dataService.post(methodName, message);
  }


  // ---------------------- broadcast messages----------------

  // getBroadcastMessageList(merchantId: string,utilityId:string,broadcastMessages: PaginationVM<BroadcastMessages, BroadcastMessageFilter>): 
  // Observable<PaginationVM<BroadcastMessages, BroadcastMessageFilter>>{
  // }

  getPreviewBroadcastMessages(merchantId: string, utilityId: string): Observable<BroadcastMessages[]> {
    return this.dataService.get<BroadcastMessages[]>(`merchant/Utilities/getPreviewBroadcastMessages/${merchantId}/${utilityId}`);
  }

  getBroadcastMessageList(merchantId: string, utilityId: string, broadCastMessages: PaginationVM<BroadcastMessages, BroadcastMessageFilter>): Observable<PaginationVM<BroadcastMessages, BroadcastMessageFilter>> {
    return this.dataService.post<PaginationVM<BroadcastMessages, BroadcastMessageFilter>, PaginationVM<BroadcastMessages, BroadcastMessageFilter>>(
      `merchant/Utilities/BroadcastMessages/${merchantId}/${utilityId}`,
      broadCastMessages
    );
  }

  AddBroadcastMessage(broadcast: BroadcastMessages, merchantId: string, utilityId: string): Observable<UtilityMessage> {
    const methodName = `merchant/Utilities/addBroadcastMessage?merchantId=${merchantId}&utilityId=${utilityId}`;
    return this.dataService.post(methodName, broadcast);
  }

  UpdateBroadcastMessage(merchantId: string, message: UpdateBroadcast, utilityId: string): Observable<UtilityMessage> {
    const methodName = `merchant/Utilities/updatebroadcast?merchantId=${merchantId}&utilityId=${utilityId}`;
    return this.dataService.post(methodName, message);
  }

  DeleteBroadcastMessages(messageId: string, merchantId: string, utilityId: string): Observable<boolean> {
    return this.dataService.delete(`merchant/Utilities/deleteBroadCastMessages/${messageId}/${merchantId}/${utilityId}`);
  }

  // ----------------------------------broadcast message by mail-------------------

  getConsumers(merchantId: string, utilityId: string): Observable<EmailItems[]> {
    return this.dataService.get<EmailItems[]>(`merchant/Utilities/getConsumersByUtility/${merchantId}/${utilityId}`);
  }

  sendMail(mail: SendMail, merchantId: string, utilityId: string): Observable<any> {
    const methodName = `merchant/Utilities/sendMailToConsumers?merchantId=${merchantId}&utilityId=${utilityId}`;
    return this.dataService.post(methodName, mail);
  }

  getSentMails(merchantId: string, utilityId: string, mails: PaginationVM<SentMailCount, SentMailFilter>): Observable<PaginationVM<SentMailCount, SentMailFilter>> {
    return this.dataService.post<PaginationVM<SentMailCount, SentMailFilter>, PaginationVM<SentMailCount, SentMailFilter>>(
      `merchant/Utilities/sentMailsList/${merchantId}/${utilityId}`, mails
    );

  }
  getSentMailsBySubject(merchantId: string, utilityId: string,bunchId:string, mails: PaginationVM<MailBySubject, SentMailFilter>): Observable<PaginationVM<MailBySubject, SentMailFilter>> {
    return this.dataService.post<PaginationVM<MailBySubject, SentMailFilter>, PaginationVM<MailBySubject, SentMailFilter>>(
      `merchant/Utilities/mailListBySubject/${merchantId}/${utilityId}/${bunchId}`, mails
    );
  }
}