import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExportToExcelService {

  constructor() { }

  ConvertToCSV(objArray,labels) 
  {
    const array =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    if (labels === 'No') {
      for (const index in objArray[0]) {
        row += index + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
    } else {
      str += labels + '\r\n';
    }
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') line += ',';


        if (array[i][index] === '' || array[i][index] === null) {
          line += ' ';
        } else {
          if (/^[0][0-9]/.test(array[i][index])) {
            array[i][index] = "'" + array[i][index];
          } else {
            array[i][index] = array[i][index];
          }
          line += array[i][index];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }

  download(data: any, filename: string, labels) {
    const csvData = this.ConvertToCSV(data, labels);
    const a: any = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    const isIE = /*@cc_on!@*/ false || !!(<any>document).documentMode;
    if (window.navigator.msSaveOrOpenBlob) {
      const retVal = navigator.msSaveBlob(blob, filename + '.csv');
    } else {
      a.download = filename + '.csv';
    }
    a.click();
  }

  ConvertToCSVExcel(objArray) // tslint:disable-next-line:one-line
  {
    const array =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (const index in objArray[0]) {
      row += index + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
  downloadExcel(data: any, filename: string) {
    const csvData = this.ConvertToCSVExcel(data);
    const a: any = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    const isIE = /*@cc_on!@*/ false || !!(<any>document).documentMode;
    if (window.navigator.msSaveOrOpenBlob) {
      const retVal = navigator.msSaveBlob(blob, filename + '.csv');
    } else {
      a.download = filename + '.csv';
    }
    a.click();
  }

}
