import { Component, OnInit } from "@angular/core";
import { BillsService } from "../bills.service";
import { MerchantBill } from "app/_shared/model/merchant-bill";
import { MerchantBillFilter } from "../_shared/model/bill-filter";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { CommonService } from "app/_core/common.service";
import { Utility } from "app/_shared/model/utility";
import { NgForm } from "@angular/forms";
import { Constant } from "app/_shared/constant";
import { UtilitiesService } from "app/utilities/utilities.service";
import { MerchantUtilitySales } from "app/dashboard/_shared/model/merchant-utility-sales";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
declare var $: any;
import * as _ from 'underscore';
import { ExportToExcelService } from "app/_core/export-to-excel.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-bill-list",
  templateUrl: "./bill-list.component.html",
  styleUrls: ["./bill-list.component.css"]
})
export class BillListComponent implements OnInit {
  urls: string;
  billHistoryList: MerchantBill[];
  merchantBillFilter: MerchantBillFilter;
  paginationVM = new PaginationVM<MerchantBill, MerchantBillFilter>();
  pageNumber: number;
  merchantId: string;
  registeredUtilities: Utility[];
  accountRegisterd = false;
  invalidAccNo = false;
  consumerDetails: MerchantBill;
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  accountPattern = Constant.NUMBER_REGEXP;
  utilities: MerchantUtilitySales[];
  billCycle: string;
  fToday = new Date();
  today = new Date();
  isPaid : boolean = false;
  isUnPaid: boolean = false;

  constructor(
    private billsService: BillsService,
    private commonService: CommonService,
    private utilitiesService: UtilitiesService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private exportToExcelService: ExportToExcelService,
    private toaster: ToastrService
  ) {
    this.billHistoryList = [];
    this.merchantBillFilter = new MerchantBillFilter();
    this.pageNumber = 1;
    this.billCycle = null;
    this.consumerDetails = new MerchantBill();
    this.registeredUtilities = new Array<Utility>();
    this.merchantId = this.commonService.getMerchantUserId();
    this.utilities = new Array<MerchantUtilitySales>();
    this.isPaid = false;
    this.isUnPaid = false;
  }

  ngOnInit() {
    if (this.router.url === '/report/paid') {
      this.isPaid = true;
    }
    if (this.router.url === '/report/unpaid') {
      this.isUnPaid = true;
    }
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.getUtilities();
  }

  onValueChange(fromDate) {
    if (fromDate) {
      this.fToday = fromDate;
    } else {
      this.fToday = this.today;
    }
  }

  /* get merchant utilities */
  getUtilities() {
    this.spinner.show();
    this.utilitiesService.merchantUtility(this.merchantId).subscribe(res => {
      if (res) {
        this.spinner.hide();
        this.utilities = res;
        this.merchantBillFilter.utilityId = this.utilities[0].id;
        if (this.isPaid) {
        this.merchantBillFilter.billFrom = this.commonService.currentDateFormat(new Date().getFullYear(),(new Date().getMonth()),1,0,0,0,0);
        this.merchantBillFilter.billTo = this.commonService.currentDateFormat(new Date().getFullYear(),new Date().getMonth() + 1,0,23,59,0,0);
        this.merchantBillFilter.billStatus = 'paid'; 
      }
      else if (this.isUnPaid) {
        this.merchantBillFilter.billFrom = this.commonService.currentDateFormat(new Date().getFullYear(),(new Date().getMonth()),1,0,0,0,0);
        this.merchantBillFilter.billTo = this.commonService.currentDateFormat(new Date().getFullYear(),new Date().getMonth() + 1,0,23,59,0,0);
        this.merchantBillFilter.billStatus = 'unpaid'; 
      }
      else{
        this.merchantBillFilter.billFrom = this.commonService.currentDateFormat(new Date().getFullYear(),(new Date().getMonth()-1),1,0,0,0,0);
        this.merchantBillFilter.billTo = this.commonService.currentDateFormat(new Date().getFullYear(),new Date().getMonth() + 1,0,23,59,0,0);
        }
        
        this.paginationVM = <PaginationVM<MerchantBill, MerchantBillFilter>>{
          pageNumber: 0,
          pageSize: 10,
          totalCount: 0,
          sortBy: "Id",
          sortType: "desc",
          records: this.billHistoryList,
          filters: this.merchantBillFilter
        };
        this.getBill();
      }
      err => {
        this.spinner.hide();
      };
    });
  }

  /* search or get bills */
  getBill() {
    this.spinner.show();
    this.billsService.getBillList(this.merchantId, this.paginationVM).subscribe(
      res => {
        if (res) {
          this.paginationVM = res;
          this.billHistoryList = new Array<MerchantBill>();
          this.billHistoryList = this.paginationVM.records;
          if (this.increase) {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          } else if (this.decrease) {
            this.showingPage = this.showingPage - this.oldPageLength;
          } else {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          }
          this.oldPageLength = this.paginationVM.records.length;
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
          this.increase = false;
          this.decrease = false;
          this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /* search bills in merchant */
  searchBillHistory() {
    this.showingPage = 0;
    this.paginationVM = <PaginationVM<MerchantBill, MerchantBillFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.billHistoryList,
      filters: this.merchantBillFilter
    };
    if (this.billCycle != null) {
      this.merchantBillFilter.billCycle = this.commonService.formatBillCycle(this.billCycle);
    }
    this.paginationVM.filters = this.merchantBillFilter;
    this.getBill();
  }

  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getBill();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getBill();
    }
  }

  /* reset bill search form */
  reset(form: NgForm) {
    this.merchantBillFilter = new MerchantBillFilter();
    this.billHistoryList = [];
    this.showingPage = 0;
   // form.resetForm();
    this.paginationVM = <PaginationVM<MerchantBill, MerchantBillFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.billHistoryList,
      filters: this.merchantBillFilter
    };
    this.merchantBillFilter.utilityId = this.utilities[0].id;
    if (this.isPaid) {
      this.merchantBillFilter.billFrom = this.commonService.currentDateFormat(new Date().getFullYear(),(new Date().getMonth()),1,0,0,0,0);
      this.merchantBillFilter.billTo = this.commonService.currentDateFormat(new Date().getFullYear(),new Date().getMonth() + 1,0,23,59,0,0);
      this.merchantBillFilter.billStatus = 'paid'; 
      }
      else if (this.isUnPaid) {
        this.merchantBillFilter.billFrom = this.commonService.currentDateFormat(new Date().getFullYear(),(new Date().getMonth()),1,0,0,0,0);
        this.merchantBillFilter.billTo = this.commonService.currentDateFormat(new Date().getFullYear(),new Date().getMonth() + 1,0,23,59,0,0);
        this.merchantBillFilter.billStatus = 'unpaid'; 
      } 
      else{
      this.merchantBillFilter.billFrom = this.commonService.currentDateFormat(new Date().getFullYear(),(new Date().getMonth()-1),1,0,0,0,0);
      this.merchantBillFilter.billTo = this.commonService.currentDateFormat(new Date().getFullYear(),new Date().getMonth() + 1,0,23,59,0,0);
      }
    this.paginationVM.filters = this.merchantBillFilter;
    this.getUtilities();
  }

  resetAccountNumber(acc) {
    if (acc === "" || acc === null) {
      this.accountRegisterd = false;
      this.invalidAccNo = false;
    }
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode("month");
  }

  /* view bill in new tab */
  viewBillAnotherTab(billId, merchantId, utilityId, accountNumber, merchantName, utilityName, templateVersion) {
      this.urls = this.commonService.viewBillAnotherTab(billId, merchantId, utilityId, accountNumber, merchantName, utilityName, templateVersion);
    }

	/* get and view pdf from azure blob storage */
  viewPdfFromCloud(pdfName: string, containerName: string) {
    const marlboro = "/bill/pdf-bills?pdfName=" + pdfName + "&containerName=" + containerName;
    this.urls = marlboro;
  }

 /* search or get bills */
 exportToExcel() {
  this.spinner.show();
  const pagination  = <PaginationVM<MerchantBill, MerchantBillFilter>>{
    pageNumber: 0,
    pageSize: this.paginationVM.totalCount,
    totalCount: 0,
    sortBy: "Id",
    sortType: "desc",
    records: new Array<MerchantBill>(),
    filters: this.merchantBillFilter,
  };
  if (this.isPaid) {
    this.merchantBillFilter.billStatus = 'paid'; 
  }
  else if (this.isUnPaid) {
    this.merchantBillFilter.billStatus = 'unpaid'; 
  }
  this.billsService.getBillList(this.merchantId, pagination).subscribe(
    res => {
      if (res) {
        res.records.forEach(item => {
           item.billDate = this.commonService.formatDueDate(new Date(item.billDate));
           item.dueDate = this.commonService.formatDueDate(new Date(item.dueDate));
           item.status = item.status ? 'Paid' : 'Not Paid';
           item.utilityName = item.utility.name;
         });
         const data = _.map(res.records, function(o) {
           return _.pick(o, 'utilityName', 'accountNumber','billUserName', 'billDate', 'dueDate', 'dueAmount', 'status');
         });
         const labels = 'Bill Type,Account #,Billing Name,Bill Date,Due Date,Due Amount($),Status';
         const today = new Date();
         const date = this.commonService.formatDate(today);
         this.exportToExcelService.download(data, 'Report_' + date, labels);
         this.toaster.success('The request data-sheet created successfully.', '');
         this.spinner.hide();
      }
    },
    err => {
      this.spinner.hide();
    }
  );
}
}
