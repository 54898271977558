export class Pagination {

  pageNumber: number;
  totalPage: number;
  pageSize: number;
  totalCount: number;
  public sortBy = 'Id';
  public sortType = 'Desc';

  constructor()
  {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.totalCount = 0;
    this.sortBy = 'Id';
    this.sortType = 'Desc';
  }

}
export class PaginationVM<T,U>  extends Pagination
{
  public  records: T[];
    public filters: U;
}
