export class MerchantUser {
    public id: string;
    public role: string; 
    public logo: string;
    public name: string;
    public address: string;
    public city: string; 
    public state: string; 
    public zip: string;
    public email: string;
    public phone: string; 
    public fax: string; 
    public contactName: string;
    public contactEmail: string;
    public contactPhone: string;
    public ContactProfile: string;
    public merchantId: string; 
    public otp:string;
    constructor()
    {
        this.id = null;
        this.role = null;
        this.logo = null;
        this.name = null;
        this.address = null;
        this.city = null;
        this.state = null;
        this.zip = null;
        this.email = null;
        this.phone = null;
        this.fax = null;
        this.contactName = null;
        this.contactEmail = null;
        this.contactPhone = null;
        this.merchantId = null;
        this.otp = null;
        
    }
}
