import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { RppsService } from '../rpps.service';

@Component({
  selector: 'app-summary-timeline',
  templateUrl: './summary-timeline.component.html',
  styleUrls: ['./summary-timeline.component.css']
})
export class SummaryTimelineComponent implements OnInit {

  merchantId: string;
  billTypeId: string;
  docId: string;
  fileSummary: any;

  constructor(public bsModalRef: BsModalRef,private rppsService: RppsService, private spinner: NgxSpinnerService) {
    this.fileSummary = new Object();
   }

  ngOnInit() {
    this.getSummary();
  }

  getSummary(): void {
    this.spinner.show();
    this.rppsService.summary(this.merchantId, this.billTypeId, this.docId).subscribe((res) => {
      if (res) {
        this.fileSummary = res;
        this.spinner.hide();
      }
      err => {
        this.spinner.hide();
      };
    });

  }
}
