import { Injectable } from '@angular/core';
import { DataService } from 'app/_core/data.service';
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { Observable } from 'rxjs';
import { AccountWithName } from './_shared/account-with-name';
import { InvoiceWithAmount } from './_shared/invoice-with-amount';
import { ProcessRPPS } from './_shared/process-rpps';
import { ReviewLogs, RPPSLogs } from './_shared/review-logs';
import { RppsMonthlyReport, RppsMonthlyReportFilter } from './_shared/rpps-monthly-report';
import { RppsSummary, RppsSummaryFilter } from './_shared/rpps-summary';
import { ViewMonthlyStats, ViewMonthlyStatsFilter } from './_shared/view-monthly-stats';

@Injectable({
  providedIn: 'root'
})
export class RppsService {

  constructor(private dataService: DataService) { }

  getRppsLogs(merchantId: string, rppsLogs: PaginationVM<RppsSummary, RppsSummaryFilter>): Observable<PaginationVM<RppsSummary, RppsSummaryFilter>> {
    return this.dataService.post<PaginationVM<RppsSummary, RppsSummaryFilter>, PaginationVM<RppsSummary, RppsSummaryFilter>>(`rpps/list/${merchantId}`, rppsLogs);
  }

  review(merchantId: string, billTypeId: string, rppsid: string): Observable<RPPSLogs>{
    return this.dataService.get(`rpps/list/${merchantId}/${billTypeId}/${rppsid}`);
  }

  getAccountNumbers(merchantId: string, billTypeId: string, accountNumber: string): Observable<AccountWithName[]>{
      return this.dataService.get(`rpps/list/getaccount/${merchantId}/${billTypeId}/${accountNumber}`);
  }

  getInvoiceNumbers(merchantId: string,billTypeId: string, accountNumber: string, invoiceNumber: string): Observable<InvoiceWithAmount[]>{
     return this.dataService.get(`rpps/list/getinvoice/${merchantId}/${billTypeId}/${accountNumber}/${invoiceNumber}`);
  }

  storeProcessedData(data: ProcessRPPS): Observable<any>{
    return this.dataService.post('rpps/list/storeprocesseddata', data);
  }

  download(merchantId: string,billTypeId: string,rppsid: string): Observable<any>{
    return this.dataService.get(`rpps/list/download/${merchantId}/${billTypeId}/${rppsid}`);
  }

  summary(merchantId: string,billTypeId: string,rppsid: string): Observable<any>{
    return this.dataService.get(`rpps/list/summary/${merchantId}/${billTypeId}/${rppsid}`);
  }

  getRppsReport(merchantId: string, rppsLogs: PaginationVM<RppsMonthlyReport, RppsMonthlyReportFilter>): Observable<PaginationVM<RppsMonthlyReport, RppsMonthlyReportFilter>> {
    return this.dataService.post<PaginationVM<RppsMonthlyReport, RppsMonthlyReportFilter>, PaginationVM<RppsMonthlyReport, RppsMonthlyReportFilter>>(`rpps/list/report/${merchantId}`, rppsLogs);
  }

  getRppsMonthlyStasLogs(merchantId: string, rppsLogs: PaginationVM<ViewMonthlyStats, ViewMonthlyStatsFilter>): Observable<PaginationVM<ViewMonthlyStats, ViewMonthlyStatsFilter>> {
    return this.dataService.post<PaginationVM<ViewMonthlyStats, ViewMonthlyStatsFilter>, PaginationVM<ViewMonthlyStats, ViewMonthlyStatsFilter>>(`rpps/list/viewstats/${merchantId}`, rppsLogs);
  }

  delete(data: any): Observable<any>{
    return this.dataService.post('rpps/list/delete', data);
  }
}
