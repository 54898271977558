import { Consumer } from 'app/account/_shared/model/consumer';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/_core/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '../account.service';
import { ConsumerDetails } from 'app/_shared/model/consumer-details';
import { Router } from '@angular/router';
import { Constant, MaxLengthConstants } from 'app/_shared/constant';
import { Merchant } from '../_shared/model/merchant';
import { NgForm } from '@angular/forms';
declare var $: any;

@Component({
	selector: 'app-edit-profile',
	templateUrl: './edit-profile.component.html'
})
export class EditProfileComponent implements OnInit {
	user: Merchant;
	showImage: boolean;
	namePattern = Constant.NAME_REGEXP;
	phonePattern = Constant.PHONE_REGEXP;
	maxlengthPhone = MaxLengthConstants.Phone;
	zipPattern = Constant.ZIP_REGEXP;
	gender: boolean;

	maxLengthPhone = MaxLengthConstants.Phone;
	maxLengthCity = MaxLengthConstants.City;
	maxLengthAddress = MaxLengthConstants.Address;

	constructor(
		private commonService: CommonService,
		private spinner: NgxSpinnerService,
		private accountService: AccountService,
		private router: Router,
		private toastr: ToastrService
	) {
		this.user = this.commonService.getMerchantData();
		this.gender = false;
		//this.user = this.commonService.getConsumerData();
		// this.user.gender = 'Male';
	}

	ngOnInit() {
		$('.btn-switch-gender').click(function() {
			$('.btn-switch-gender.active').removeClass('active');
			$(this).addClass('active');
		});

		$('.bill-box .title-bill, .bill-box .logo, .bill-box .full-view').click(function() {
			$('.all-bills').hide();
			$('.single-bill').show();
		});

		this.getUser();
	}

	getUser() {
		this.spinner.show();
		this.accountService.GetUser(this.user.email).subscribe((res) => {
			if (res) {
				this.user = res;
				this.spinner.hide();
			}
		});
		this.spinner.hide();
	}

	updateUser(form: NgForm) {
		if (form.valid) {
			this.spinner.show();
			this.accountService.UpdateUser(this.user).subscribe(
				(data) => {
					if (data) {
						this.accountService.updateProfileOnNavbar(true);
						this.router.navigate([ '/dashboard' ]);
						this.spinner.hide();
					}
				},
				(err) => {
					this.spinner.hide();
				}
			);
		}
	}



	onImageFileChange(evt: any) {
		this.spinner.show();
		const reader = new FileReader();
		if (evt.target.files && evt.target.files.length > 0) {
			const file = evt.target.files[0];
			reader.readAsDataURL(file);
			reader.onload = () => {
				// this.user.profilePhoto = 'data:image/jpg;base64,' + reader.result.toString().split(',')[1];
				this.showImage = true;
				this.spinner.hide();
			};
		}
	}

	onImageFileClick(img: any) {
		img.target.value = '';
	}
}
