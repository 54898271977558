import { ChangePasswordComponent } from "app/account/change-password/change-password.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { RouterModule } from "@angular/router";
import { SpinnerComponent } from "./spinner.component";
import { EditProfileComponent } from "app/account/edit-profile/edit-profile.component";
import { TransactionViewPipe } from "./transaction-view.pipe";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { BillListComponent } from "app/bills/bill-list/bill-list.component";
import { PaymentListComponent } from "app/payments/payment-list/payment-list.component";
import { PrivacyPalicyComponent } from "./privacy-palicy/privacy-palicy.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { SessionOutComponent } from "./session-out/session-out.component";
import { ModalMailListComponent } from "app/utilities/modal-mail-list/modal-mail-list.component";
import { BillNotificationSummaryComponent } from "app/summary/bill-notification-summary/bill-notification-summary.component";
import { BillChartComponent } from "app/bills/bill-chart/bill-chart.component";
import { PaymentReceiptComponent } from "app/payments/payment-receipt/payment-receipt.component";
import { BtxPaymentsComponent } from "app/payments/btx-payments/btx-payments.component";
import { AddNewUserComponent } from "app/usermanagement/add-new-user/add-new-user.component";
import { DemandPaymentReceiptComponent } from "app/usermanagement/demand-payment-receipt/demand-payment-receipt.component";
import { RppsLogsComponent } from "app/rpps/rpps-logs/rpps-logs.component";
import { ReviewRecordsComponent } from "app/rpps/review-records/review-records.component";
import { SummaryTimelineComponent } from "app/rpps/summary-timeline/summary-timeline.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { AchPaymentsReportComponent } from "app/payments/ach-payments-report/ach-payments-report.component";
import { AddAdhocPaymentComponent } from "./add_adhoc_payment/add_adhoc_payment.component";
import { AdhocPaymentListComponent } from "./adhoc_payment_list/adhoc_payment_list.component";

@NgModule({
  declarations: [
    SpinnerComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    TransactionViewPipe,
    BillListComponent,
    PaymentListComponent,
    TermsAndConditionsComponent,
    PrivacyPalicyComponent,
    SessionOutComponent,
    ModalMailListComponent,
    BillNotificationSummaryComponent,
    BillChartComponent,
    PaymentReceiptComponent,
    BtxPaymentsComponent,
    AddNewUserComponent,
    DemandPaymentReceiptComponent,
    // RppsPaymentComponent
    RppsLogsComponent,
    ReviewRecordsComponent,
    SummaryTimelineComponent,
    AchPaymentsReportComponent,
    AddAdhocPaymentComponent,
    AdhocPaymentListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TimepickerModule.forRoot(),
    NgSelectModule,
  ],
  exports: [
    SpinnerComponent,
    // BillDetailsComponent,
    // ListBillsPaymentComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    TransactionViewPipe,
    BillListComponent,
    PaymentListComponent,
    TermsAndConditionsComponent,
    PrivacyPalicyComponent,
    SessionOutComponent,
    ModalMailListComponent,
    BillNotificationSummaryComponent,
    BillChartComponent,
    PaymentReceiptComponent,
    BtxPaymentsComponent,
    AddNewUserComponent,
    DemandPaymentReceiptComponent,
    // RppsPaymentComponent
    // BillChartComponent,
    // MerchantListPaymentsComponent,


    RppsLogsComponent,
    ReviewRecordsComponent,
    SummaryTimelineComponent,
    AchPaymentsReportComponent,
    AddAdhocPaymentComponent,
    AdhocPaymentListComponent
  ],
  providers: [],
  entryComponents: [SummaryTimelineComponent],
  bootstrap: [SessionOutComponent],
})
export class SharedModule {}
