import { ItemId } from 'app/_shared/model/item-id';
import { stringify } from 'querystring';

export class Customers {
  public id: string;
  public accountNumber: string;
  public prefix: string;
  public firstName: string;
  public lastName: string;
  public amount: number;
  public createdOn: string;
  public paymentDate: string;
  public email: string;
  public transactionId: string;
  public utility: ItemId;
  public merchant: ItemId;
  public reason: string;
  constructor() {
    this.id = null;
    this.accountNumber = null;
    this.prefix = null;
    this.firstName = null;
    this.lastName = null;
    this.amount = null;
    this.createdOn = null;
    this.paymentDate = null;
    this.email = null;
    this.transactionId = null;
    this.utility = new ItemId();
    this.merchant = new ItemId();
    this.reason = null;
  }
}

export class CustomerFilter {
  public utilityId: string;
  public from: Date;
  public to: Date;
  constructor() {
    this.utilityId = null;
    this.from = null;
    this.to = null;
  }
}
