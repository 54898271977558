import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

const MINUTES_UNITL_AUTO_LOGOUT = 20; // in mins
const CHECK_INTERVAL = 2000; // in ms
const STORE_KEY = 'lastAction';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {
  isTimeout: boolean;
  isOutTime: string;
  private eventCallback = new Subject<string>();
  eventCallback$ = this.eventCallback.asObservable();
  public getLastAction() {
    return JSON.parse(localStorage.getItem('lastAction'));
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router) {
    this.initListener();
    this.initInterval();
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeLeft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60000;
    const diff = timeLeft - now;
    this.isTimeout = diff < 0;
    this.isOutTime = (this.isTimeout).toString();
    if (this.isTimeout) {
      this.eventCallback.next(this.isOutTime);
    }
  }
}
