import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './layouts/main.component';
import { StaticComponent } from './layouts/static.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutsModule } from './layouts/layouts.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxEditorModule } from 'ngx-editor';
import { ModalMailListComponent } from './utilities/modal-mail-list/modal-mail-list.component';
import { BillNotificationSummaryComponent } from './summary/bill-notification-summary/bill-notification-summary.component';
import { PreviewMailComponent } from './utilities/preview-mail/preview-mail.component';
import { HttpInterceptorService } from './_core/http-interceptor.service';
import { DatePipe } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


@NgModule({
	declarations: [ AppComponent, StaticComponent, MainComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
    FormsModule,
    NgSelectModule,
    NgxEditorModule,
		NgxSpinnerModule,
		BrowserAnimationsModule,
    LayoutsModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      countDuplicates: false,
      progressBar: true,
      closeButton: true,
    }) // ToastrModule added
  ],
  providers:[ { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }, DatePipe],
  bootstrap: [ AppComponent ],
  entryComponents: [ModalMailListComponent,BillNotificationSummaryComponent],
})
export class AppModule {}
