import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Merchant } from "app/account/_shared/model/merchant";
import { CommonService } from "app/_core/common.service";
import { PaymentsService } from "../payments.service";
import { MerchantPayment } from "../_shared/model/merchant-payment";
import { MerchantPaymentFilter } from "app/bills/_shared/model/bill-filter";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { MerchantUser } from "app/account/_shared/model/merchant-user";
import { Utility } from "app/_shared/model/utility";
import { NgForm } from "@angular/forms";
import { Constant } from "app/_shared/constant";
import { UtilitiesService } from "app/utilities/utilities.service";
import { MerchantUtilitySales } from "app/dashboard/_shared/model/merchant-utility-sales";
import { NgxSpinnerService } from "ngx-spinner";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { ItemId } from 'app/_shared/model/item-id';
import { ExportToExcelService } from 'app/_core/export-to-excel.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-payment-list",
  templateUrl: "./payment-list.component.html",
  styleUrls: ["./payment-list.component.css"]
})
export class PaymentListComponent implements OnInit {
  hideViewBtn: boolean;
  hidePagination: boolean = true;
  hideSearchBox = false;
  hidePadding = false;
  merchantId: string;
  urls: string;
  pageNumber: number;
  merchantPaymentFilter: MerchantPaymentFilter;
  paymentHistoryList: MerchantPayment[];
  paginationVM = new PaginationVM<MerchantPayment, MerchantPaymentFilter>();
  today = new Date();
  fToday = new Date();
  toDate: Date;
  minDate: Date;
  consumerDetails: MerchantPayment;
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  registeredUtilities: Utility[];
  accountRegisterd = false;
  invalidAccNo = false;
  accountPattern = Constant.NUMBER_REGEXP;
  utilities: MerchantUtilitySales[];
  pageSize: number;
  isMastercardPayment: boolean = false;
  paymentModes: string[];
  cardServiceProviders: string[];
  isCardType=false
  constructor(
    private router: Router,
    private paymentsService: PaymentsService,
    private commonService: CommonService,
    private utilitiesService: UtilitiesService,
    private spinner: NgxSpinnerService,
    private exportToExcelService: ExportToExcelService,
    private toaster: ToastrService
  ) {
    this.paymentHistoryList = [];
    this.merchantPaymentFilter = new MerchantPaymentFilter();
    this.pageNumber = 1;
    this.registeredUtilities = new Array<Utility>();
    this.consumerDetails = new MerchantPayment();
    this.merchantId = this.commonService.getMerchantUserId();
    this.toDate = this.minDate;
    this.utilities = new Array<MerchantUtilitySales>();
    this.paymentModes = new Array<string>();
    this.cardServiceProviders = new Array<string>();
    this.merchantPaymentFilter.from = this.commonService.currentDateFormat(new Date().getFullYear(),(new Date().getMonth()-2),new Date().getDate(),0,0,0,0);
    this.merchantPaymentFilter.to = this.commonService.currentDateFormat(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(),23,59,0,0);
    this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.paymentHistoryList,
      filters: this.merchantPaymentFilter
    };

  }

  ngOnInit() {
    if( this.router.url === "/master-card/mastercard-paymentlist" ){
      this.isMastercardPayment = true;
    } 

    if(this.router.url ==="/report/payment-report"){
      this.isCardType=true
    }
    this.getUtilities();
    if(this.isCardType){
      this.merchantPaymentFilter.cardType="Credit Card"
    }

    if (this.router.url === "/payments") {
      this.hideViewBtn = false;
    }
    if (this.router.url === "/dashboard") {
      this.hidePagination = false;
      this.hideSearchBox = true;
      this.hidePadding = true;
      this.hideViewBtn = true;
    }
    this.pageSize = this.hideViewBtn ? 5 : 10;
    this.paginationVM.pageSize = this.pageSize;
  }

  viewPayment() {
    this.router.navigate(["/payments"]);
  }

  /* get payment list */
  getPayment() {
    this.spinner.show();
    this.paymentsService.getPaymentHistory(this.merchantId, this.paginationVM).subscribe(
      res => {
        this.paymentHistoryList = [];
        if (res) {
          this.paginationVM = res;
          this.paymentHistoryList = new Array<MerchantPayment>();
          this.paymentHistoryList = this.paginationVM.records;
          if (this.increase) {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          } else if (this.decrease) {
            this.showingPage = this.showingPage - this.oldPageLength;
          } else {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          }
          this.oldPageLength = this.paginationVM.records.length;
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
          this.increase = false;
          this.decrease = false;
          this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  getMastercardPayments(){
     this.spinner.show();
     this.paymentsService.getMastercardPaymentHistory(this.merchantId, this.paginationVM).subscribe(
      res => {
        this.paymentHistoryList = [];
        if (res) {
          this.paginationVM = res;
          this.paymentHistoryList = new Array<MerchantPayment>();
          this.paymentHistoryList = this.paginationVM.records;
          if (this.increase) {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          } else if (this.decrease) {
            this.showingPage = this.showingPage - this.oldPageLength;
          } else {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          }
          this.oldPageLength = this.paginationVM.records.length;
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
          this.increase = false;
          this.decrease = false;
          this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /* get merchant utilities */
  getUtilities() {
    // this.spinner.show();
    this.utilitiesService.merchantUtility(this.merchantId).subscribe(res => {
      if (res) {
        // this.spinner.hide();
        this.utilities = res;
        this.merchantPaymentFilter.utilityId = this.utilities[0].id;
        this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
          pageNumber: 0,
          //pageSize: 10,
          pageSize: this.pageSize,
          totalCount: 0,
          sortBy: "Id",
          sortType: "desc",
          records: this.paymentHistoryList,
          filters: this.merchantPaymentFilter
        };
       if(this.isMastercardPayment){
        this.getMastercardPayments();
       } else{
         if(this.isCardType){
           this.getCardServiceProviders();
           this.getPayment();
        }
        else{
          this.getPaymentModes();
          this.getPayment();
        }
       }
      }
      err => {
        this.spinner.hide();
      };
    });
  }

  /* search payment */
  searchBillHistory() {
    this.showingPage = 0;
    this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
      pageNumber: 0,
      //pageSize: 10,
      pageSize: this.pageSize,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.paymentHistoryList,
      filters: this.merchantPaymentFilter
    };
    if(this.isCardType){
      this.merchantPaymentFilter.cardType="Credit Card";
    }
    if(this.merchantPaymentFilter.from != null) {
        // tslint:disable-next-line: max-line-length
        this.merchantPaymentFilter.from = this.commonService.currentDateFormat(this.merchantPaymentFilter.from.getFullYear(),this.merchantPaymentFilter.from.getMonth(),this.merchantPaymentFilter.from.getDate(),0,0,0,0);
    }

    if(this.merchantPaymentFilter.to != null) {
    // tslint:disable-next-line: max-line-length
    this.merchantPaymentFilter.to = this.commonService.currentDateFormat(this.merchantPaymentFilter.to.getFullYear(),this.merchantPaymentFilter.to.getMonth(),this.merchantPaymentFilter.to.getDate(),23,59,0,0);
    }
    this.paginationVM.filters = this.merchantPaymentFilter;
    if(this.isMastercardPayment){
      this.getMastercardPayments();
     } else{
       if(this.isCardType){
         this.getCardServiceProviders();
         this.getPayment();
      }
      else{
        this.getPaymentModes();
        this.getPayment();
      }
     }
  }


  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getPayment();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getPayment();
    }
  }

  /* reset payment foorm */
  reset(form: NgForm) {
    this.merchantPaymentFilter = new MerchantPaymentFilter();
    this.paymentHistoryList = [];
    this.showingPage = 0;
    form.resetForm();
    this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
      pageNumber: 0,
      //pageSize: 10,
      pageSize: this.pageSize,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.paymentHistoryList,
      filters: this.merchantPaymentFilter
    };
    this.merchantPaymentFilter.utilityId = this.utilities[0].id;
    if(this.isCardType){
      this.merchantPaymentFilter.cardType="Credit Card";
      this.merchantPaymentFilter.cardServiceProvider=""
    }
    this.merchantPaymentFilter.paymentMode = "";
    this.merchantPaymentFilter.from = this.commonService.currentDateFormat(new Date().getFullYear(),(new Date().getMonth()-2),new Date().getDate(),0,0,0,0);
    this.merchantPaymentFilter.to = this.commonService.currentDateFormat(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(),23,59,0,0);
    this.paginationVM.filters = this.merchantPaymentFilter;
    this.getUtilities();
  }

  onValueChange(fromDate) {
    if (fromDate) {
      this.fToday = fromDate;
    } else {
      this.fToday = this.today;
    }
  }

  /* reset account number */
  resetAccountNumber(acc) {
    if (acc === "" || acc === null) {
      this.accountRegisterd = false;
      this.invalidAccNo = false;
    }
  }

  /* view payment receipt in new tab */
  viewPaymentReceipt(paymentId: string, merchantId: string, utilityId: string) {
    const receipt = "/payment/payment-receipt?id=" + paymentId + "&merchantId=" + merchantId + "&utilityId=" + utilityId;
    this.urls = receipt;
  }


  getPaymentModes(){
    this.paymentsService.getPaymentModes(this.merchantId,this.merchantPaymentFilter.utilityId).subscribe(res=>{
      if(res){
        this.paymentModes = res;
      }
    })
  }

  getCardServiceProviders(){
    this.paymentsService.getCardServiceProviders(this.merchantId,this.merchantPaymentFilter.utilityId).subscribe(res=>{
      if(res){
        this.cardServiceProviders = res;
      }
    })
  }
    /* opt in list export to excel */
    exportToExcel() {
      this.spinner.show();
      this.merchantPaymentFilter.from = this.commonService.currentDateFormat(this.merchantPaymentFilter.from.getFullYear(),this.merchantPaymentFilter.from.getMonth(),this.merchantPaymentFilter.from.getDate(),0,0,0,0);
      this.merchantPaymentFilter.to = this.commonService.currentDateFormat(this.merchantPaymentFilter.to.getFullYear(),this.merchantPaymentFilter.to.getMonth(),this.merchantPaymentFilter.to.getDate(),23,59,0,0);
      this.paymentsService.exportPaymentToExcel(this.merchantId, this.merchantPaymentFilter).subscribe(
        res => {
          if (res) {
            res.forEach(item => {
              const due = new Date(item.dueDate);
              const paymentDate = new Date(item.transactionDate);
              item.dueDate = this.commonService.formatDueDate(due);
              item.transactionDate = this.commonService.formatDueDate(paymentDate);
            });
            var labels:any
            var data:any
            if(this.isCardType){
               data = _.map(res, function(o) {
                return _.pick(o, 'transactionReference', 'transactionDate', 'amountPaid', 'dueDate', 'accountNumber','name','paymentChannel','cardServiceProvider');
              });
            labels = 'Transaction ref, Transaction Date, Amount Paid ($), Due Date, Account No.,Consumer Name,Payment Channel,Card  Service Provider';

            }else{
               data = _.map(res, function(o) {
                return _.pick(o, 'transactionReference', 'transactionDate', 'amountPaid', 'dueDate', 'accountNumber','name','paymentChannel','paymentMode');
              });
            labels = 'Transaction ref, Transaction Date, Amount Paid ($), Due Date, Account No.,Consumer Name,Payment Channel,Payment Mode';

            }

            const today = new Date();
            const date = this.commonService.formatDate(today);
            this.exportToExcelService.download(data, 'Report_' + date, labels);
            this.toaster.success('The request data-sheet created successfully.', '');
            this.spinner.hide();
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }
}
