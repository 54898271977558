import { CommonService } from './../../_core/common.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentsService } from '../payments.service';
import { BillsService } from 'app/bills/bills.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Receipt } from 'app/_shared/model/receipt-model';

@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.css']
})
export class PaymentReceiptComponent implements OnInit {
  receipt: Receipt;
  private unsubscribe$ = new Subject<void>();
  transactionID: string;
  paymentDateTime: Date;
  transactionState: string;
  amount: number;
  cardType: string;
  maskedPan: string;
  currencyCode: string;
  accountNumber: string;
  billUserName: string;
  billNumber: string;
  billCycle: string;
  billDate: Date;
  billId: string;
  merchantId: string;
  utilityId: string;
  countyReceipt= false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentsService,
    private commonService: CommonService,
    private router: Router
  ) {
    this.receipt = new Receipt();
    this.countyReceipt = this.router.url.includes('county-home');
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        tap((queryParams: Params) => {
          this.billId = queryParams['id'];
          this.merchantId = queryParams['merchantId'];
          this.utilityId = queryParams['utilityId'];
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        () => {},
        err => {}
      );
    this.getPaymentReceipt(this.billId, this.merchantId, this.utilityId);
  }

  /* get payment receipt */
  getPaymentReceipt(billId, merchantId, utilityId) {
    this.spinner.show();
    this.paymentService
      .GetPaymentReceipt(billId, merchantId, utilityId)
      .subscribe(
        res => {
          this.receipt = res;
          this.receipt.convenienceFee = this.receipt.convenienceFee != null ? this.receipt.convenienceFee : 0;
          this.receipt.totalAmount =
            this.receipt.amount + this.receipt.convenienceFee;
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        }
      );
  }

  /* export payment receipt to pdf */
  exportToPDF(isDownload: boolean) {
    this.spinner.show();
    this.paymentService
      .ExportToPDF(this.billId, this.merchantId, this.utilityId, 'Original')
      .subscribe(
        res => {
          if (res) {
            if (isDownload) {
              this.downloadFile(
                res.result,
                this.receipt.accountNumber,
                'application/pdf',
                'download'
              );
              this.spinner.hide();
            } else {
              this.downloadFile(
                res.result,
                this.receipt.accountNumber,
                'application/pdf',
                'print'
              );
              this.spinner.hide();
            }
          }
        },
        err => {
          this.spinner.hide();
        }
      );
  }

  /* download pdf */
  downloadFile(
    data: string,
    accountNumber: string,
    fileType: any,
    type: string
  ) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    if (type === 'print') {
      window.location.href = url;
      this.spinner.hide();
    } else {
      const downloadPDF = document.createElement('a');
      downloadPDF.href = url;
      const today = new Date();
      const date = this.commonService.formatDate(today);
      downloadPDF.download = accountNumber + '_' + date + '.pdf';
      downloadPDF.click();
      this.spinner.hide();
    }
  }
}
