// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
//  api: "http://localhost:62001/",
api: "https://demomerchant.billtrax.online/API/",
//  api: "https://demomerchant.billtrax.online/API/",
  // api: "https://demomerchant.billtrax.online/API/",
  // api: "https://demomerchant.billtrax.online/API/",
  // api: 'http://137.117.107.54:91/API/'
  // api: "https://merchant.billtrax.com/API/", // production merchant
  // api: 'http://137.117.107.54:94/API/',   // for demo site
};
/*s
 * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
