import { Consumer } from './../account/_shared/model/consumer';
import { ConsumerDetails } from './../_shared/model/consumer-details';
import { Injectable } from '@angular/core';
import { Merchant } from 'app/account/_shared/model/merchant';
import { AccountService } from 'app/account/account.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SessionOutComponent } from 'app/_shared/session-out/session-out.component';
import { MerchantUser } from 'app/account/_shared/model/merchant-user';
import { SiteVisit } from 'app/dashboard/_shared/model/site-visit';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  sessionId: string;
  bsModalRef: BsModalRef;

  constructor(private accountService: AccountService, private router: Router,private modalService: BsModalService, private dataService: DataService,
    private datePipe: DatePipe) {
    this.sessionId = this.getSession();
  }

  getMerchantData(): Merchant {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  getMerchanUsertData(): MerchantUser {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  getMerchantUserId(): string {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    return user.merchantId;
  }

  getMerchantUserName(): string {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    return user.contactName;
  }

  getSession(): string {
    return sessionStorage.getItem('sessionId');
  }

  formatDate(value) {
    return value.getMonth() + 1 + '' + value.getDate() + '' + value.getFullYear();
  }

  formatDueDate(value) {
    return value.getMonth() + 1 + '/' + value.getDate() + '/' + value.getFullYear();
  }

  formatBillCycle(value) {
    return value.getMonth() + 1 + '/' + value.getFullYear();
  }

  formatDateMMDDYYYY(value) {
    const date = new Date(value);
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    return dd + '/' + mm + '/' + yyyy;
  }



  forceLogout() {
		this.accountService.LogOut(this.sessionId).subscribe(res => {
			if (res) {
        // this.router.navigate([ '/' ]);
        this.bsModalRef = this.modalService.show(SessionOutComponent,{ class: 'modal-md', backdrop: 'static' });
			}
		});
  }

  formatDateMMYYYY(value) {
    const month = parseInt(value.substring(0, 2));
    const year = value.substring(3, 7);
    const monthNames = {
    '1':'Jan',
    '2':'Feb',
    '3':'Mar',
    '4':'Apr',
    '5':'May',
    '6':'June',
    '7':'July',
    '8':'Aug',
    '9':'Sept',
    '10':'Oct',
    '11':'Nov',
    '12':'Dec'
    };
   return monthNames[month] + ' - ' + year;
  }

  viewBillAnotherTab(billId, merchantId, utilityId, accountNumber, merchantName, utilityName, tempVersion) {
    if (utilityName === 'MRWC Water Sales' && merchantName === 'Meansville-Riley Water Company, Inc' && tempVersion === 'V1') {
      const marlboro =
        '/bill/view-bill?billId=' + billId + '&merchantId=' + merchantId + '&utilityId=' + utilityId + '&accountNumber=' + accountNumber;
      return marlboro;
    } else if (utilityName === 'MRWC Water Sales' && merchantName === 'Meansville-Riley Water Company, Inc' && tempVersion === 'V2') {
      const marlboro =
        '/bill/mrwc-v2?billId=' + billId + '&merchantId=' + merchantId + '&utilityId=' + utilityId + '&accountNumber=' + accountNumber;
      return  marlboro;
    }
  }

  viewPdfFromCloud(pdfName: string, containerName: string) {
    const path = '/bill/pdf-bills?pdfName=' + pdfName + '&containerName=' + containerName;
    return path;
  }

  siteVisit(browserDetails: SiteVisit): Observable<SiteVisit> {
    return this.dataService.post('consumers/sitevisit', browserDetails);
  }

  currentDateFormat(y: number,m: number,d: number,h: number, min: number, sec: number, ms: number) {
    const date = new Date();
    date.setFullYear(y);
    date.setMonth(m);
    date.setDate(d);
    date.setHours(h);
    date.setMinutes(min);
    date.setSeconds(sec);
    date.setMilliseconds(ms);
    return date;
  }


  downloadFile(
    data: string,
    reportName: string,
    fileType: any,
    type: string
  ) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    if (type === 'print') {
      window.location.href = url;
    } else {
      const downloadPDF = document.createElement('a');
      downloadPDF.href = url;
      const today = new Date();
      const date = this.datePipe.transform(new Date(today), 'MM/dd/yyyy hh:mm:ss');;
      downloadPDF.download = reportName + '_' + date + '.pdf';
      downloadPDF.click();
    }
  }

}
