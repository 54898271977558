export class BillSummary {
  public id: string;
  public billId: string;
  public summary: string;
  public createdOn: Date;
  public emails: Emails[];
  constructor() {
    this.id = null;
    this.billId = null;
    this.summary = null;
    this.createdOn = new Date();
    this.emails = new Array<Emails>();
  }
}

export class Emails {
  public email: string;
  public statusCode: string;
  public clicksCount: number;
  public opensCount: number;
  public lastEventTime: Date;
  public mailReferenceId: string;
  constructor() {
    this.email = null;
    this.statusCode = null;
    this.clicksCount = 0;
    this.clicksCount = 0;
    this.lastEventTime = null;
    this.mailReferenceId = null;
  }
}
