export class Receipt {
  public id: string;
  public merchantId: string;
  public utilityId: string;
  public transactionID: string;
  public paymentDateTime: string;
  public transactionState: string;
  public amount: number;
  public cardType: string;
  public maskedPan: string;
  public currencyCode: string;
  public accountNumber: string;
  public billUserName: string;
  public billNumber: string;
  public billCycle: string;
  public billDate: string;
  public errorCode: string;
  public isUtilityRegister: boolean;
  public email: string;
  public merchantLogo: string;
  public utilityName: string;
  public merchantName: string;
  public paidBy: string;
  public convenienceFee: number;
  public totalAmount: number;
  public afterDueDate: number;
  public paymentStatus: string;
  public paymentChannel: string;
  constructor() {
    this.id = null;
    this.merchantId = null;
    this.utilityId = null;
    this.transactionID = null;
    this.paymentDateTime = null;
    this.transactionState = null;
    this.amount = null;
    this.cardType = null;
    this.maskedPan = null;
    this.currencyCode = null;
    this.accountNumber = null;
    this.billUserName = null;
    this.billNumber = null;
    this.billCycle = null;
    this.billDate = null;
    this.errorCode = null;
    this.isUtilityRegister = false;
    this.email = null;
    this.merchantLogo = null;
    this.utilityName = null;
    this.merchantName = null;
    this.paidBy = null;
    this.convenienceFee = null;
    this.totalAmount = null;
    this.afterDueDate = null;
    this.paymentStatus = null;
    this.paymentChannel = null;
  }
}
