import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-out',
  templateUrl: './session-out.component.html',
  styleUrls: ['./session-out.component.css']
})
export class SessionOutComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef,private router: Router) { }

  ngOnInit() {
  }

  logout(modal){
    modal.hide();
    sessionStorage.clear();
    this.router.navigate([ '/' ]);
  }

}
