import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "app/_core/common.service";
import { UtilitiesService } from "app/utilities/utilities.service";
import { MerchantUtilitySales } from "app/dashboard/_shared/model/merchant-utility-sales";
import { AccountService } from "app/account/account.service";
import { Utility } from "app/_shared/model/utility";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { ExportToExcelService } from "app/_core/export-to-excel.service";
declare var intellipay: any;
import * as _ from "underscore";
import { Constant } from "app/_shared/constant";
import { BillerService } from "app/usermanagement/biller.service";
import { element } from "protractor";
import { User } from "../model/user.model";
import { MerchantUser } from "app/usermanagement/_shared/merchant-user";
import { Location } from "@angular/common";
import { log } from "console";

@Component({
  selector: "app-add_adhoc_payment",
  templateUrl: "./add_adhoc_payment.component.html",
  styleUrls: ["./add_adhoc_payment.component.css"],
})
export class AddAdhocPaymentComponent implements OnInit {
  @ViewChild("payNowDashboard", { static: false }) payNowDashboard: ElementRef;
  @ViewChild("payNowDashboard1", { static: false }) payNowDashboard1: ElementRef;
  @ViewChild("payNowDashboard2", { static: false }) payNowDashboard2: ElementRef;
  @ViewChild("payNowDashboard3", { static: false }) payNowDashboard3: ElementRef;



  openForm: boolean;
  merchantId: string;
  utilities: any;
  urls: string;
  emailPattern = Constant.EMAIL_REGEXP;
  amountPattern = Constant.AMOUNT;
  AdhocPaymentField: any = [];
  utilityId: any;
  interval: NodeJS.Timer;
  requiredFields = ["Email", "Amount"];
  utilityName: any;
  firstName: any;
  lastName: any;
  email: any;
  amount: any;
  user: any = MerchantUser;
  merchantUser: MerchantUser;
  // terminalList: any;
  amountValidation: boolean = true;
  terminal: any;
  reason: any;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
    private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private billerService: BillerService,
    private commonService: CommonService,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    // this.merchantId = this.commonService.getMerchantUserId();
    this.user = this.commonService.getMerchanUsertData();
    this.utilities = new Array<Utility>();
    this.merchantUser = new MerchantUser();

    // this.getUtilities();
    this.AdhocPaymentField = new Array<any>();
  }

  ngOnInit() {
    this.getUtilities();
  }

  /*Get list of utilities registered for perticular merchant*/
  getUtilities() {
    this.spinner.show();

    this.accountService.getUtilitiesFromMerchant(this.user.merchantId, false).subscribe(
      (res: any) => {
        this.utilities = res.Fields;
        this.AdhocPaymentField = res.Fields[0].AdhocPaymentFields;
        this.utilityId = this.utilities[0].Id;
        this.utilityName = res.Fields[0].Name;
        // this.terminalList = res.TerminalList;
        // this.terminal = this.terminalList[0].Hid;
        this.spinner.hide();
      },
      (err) => {}
    );
  }

  onChange(event) {
    this.utilities.forEach((element: any) => {
      var a: any = ([] = this.utilities.filter((element1: any) => element1.Id == event));
      this.AdhocPaymentField = a[0].AdhocPaymentFields;
    });
  }

  //  intializePaymentGateway(Customer: NgForm): void {
  //   if(Customer.form.invalid)
  //     return;
  //   this.spinner.show();
  //   this.billerService.lightboxEmvIn(this.user.merchantId, this.utilityId).subscribe(
  //     (res) => {
  //         this.spinner.hide();
  //          if (res) {
  //           const rg = document.createRange();
  //           document.documentElement.appendChild(
  //             rg.createContextualFragment(res.result)
  //           );
  //           intellipay.setItemLabel('account', 'Account Number');
  //           intellipay.disable('email');
  //           intellipay.initialize();
  //           const _this = this;
  //           intellipay.runOnApproval(function (response: any) {
  //             _this.storeNewCustomerPayment(JSON.stringify(response),Customer.form.value);
  //           });
  //           this.interval = setInterval(() => {
  //             if (intellipay.isReady) {
  //               clearInterval(this.interval);
  //               _this.addCustomer()
  //             }
  //           }, 2000);
  //         }
  //      });
  //     // (err) => {
  //     //   this.spinner.hide();
  //     //   this.toaster.error(err.Errors[0].Message);
  //     // }
  //  // );
  // }

  addCustomer() {
    this.spinner.hide();
    this.payNowDashboard3.nativeElement.attributes[3].nodeValue = 'Adhoc';
    this.payNowDashboard1.nativeElement.attributes[7].nodeValue = this.amount;
    this.payNowDashboard2.nativeElement.attributes[7].nodeValue =
   this.AdhocPaymentField.length > 0 ? this.AdhocPaymentField.find((field) => field.FieldName == "Field1").FieldValue : "NA";

    this.payNowDashboard.nativeElement.click();
  }

  createAdhocPayment(form: NgForm) {
    if (form.valid) {
      this.spinner.show();
      var data = {
        utility: { id: this.utilityId },
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        amount: this.amount,
        terminal: this.terminal,
        reason: this.reason,
        merchant: {
          id: this.user.merchantId,
        },
      };

      this.AdhocPaymentField.forEach((x, index) => {
        Object.assign(data, { ["field" + (index + 1)]: x.FieldValue });
      });
      this.billerService.createAdhocPayment(data).subscribe((res) => {
        if (res) {
          if (res.status == "SALE COMPLETED") {
            this.spinner.hide();
            this.toaster.success(res.status);
            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.amount = "";
            this.reason = "";
            // this.AdhocPaymentField = this.AdhocPaymentField.map((element) => (element.FieldValue = ""));
            this.AdhocPaymentField.forEach((x, index) => {
              x.FieldValue = "";
              this.AdhocPaymentField[index] = x;
            });
            const receipt = "/demand-receipt?merchantId=" + res.merchant.id + "&paymentId=" + res.id;
            window.open(receipt);
            // this.router.navigate(["/biller-support/add-adhoc-payment-list"]);
          }
          // this.router.navigate(['/biller-support/demand-receipt'], {
          //   queryParams: {
          //     merchantId: res.merchant.id,
          //     paymentId: res.id
          //   }
          // });
          else {
            this.toaster.error(res.status);
            this.spinner.hide();
          }
        }
      });
    }
  }

  resetSearch(form: NgForm) {
    form.resetForm();
    this.getUtilities();
  }
  checkAmount() {
    if (this.amount.length > 0) {
      var regExp = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
      if (this.amount.match(regExp)) {
        this.amountValidation = true;
      } else {
        this.amountValidation = false;
      }
    } else {
      this.amountValidation = true;
    }
  }
  navigate() {
    sessionStorage.setItem("billTypeId", this.utilityId);
    this.location.back();
  }
  // reloadTerminals() {
  //   this.spinner.show();

  //   this.billerService.reloadTerminals(this.user.merchantId).subscribe((res) => {
  //     if (res) {
  //       this.terminalList = res;
  //       this.terminal = this.terminalList[0].Hid;
  //       this.spinner.hide();
  //     }
  //   });
  // }
  callIntellipay(form: NgForm) {
    if (form.valid) {
      this.spinner.show();
      this.billerService.InitializeLightBoxEmvIntelliPayForMcFarland(this.user.merchantId).subscribe((res) => {
        if (res) {
          const rg = document.createRange();
          document.documentElement.appendChild(rg.createContextualFragment(res.result));
          intellipay.setItemLabel("account", "Account Number");
          intellipay.setItemLabel("button", "Pay Now"); //Set the pay button text
          intellipay.disable("email");
          intellipay.initialize();
          // this.spinner.hide();
          const _this = this;
          intellipay.runOnApproval(function (response) {
            intellipay.closeModal();
            _this.storeNewCustomerPayment(JSON.stringify(response));
          });
          // setTimeout(function () {
          //   _this.addCustomer();
          //   _this.spinner.hide();
          // }, 1000);
          this.interval = setInterval(() => {
            if (intellipay.isReady) {
              clearInterval(this.interval);
              _this.addCustomer();
            }
          }, 2000);
        }
      });
    }
  }
  storeNewCustomerPayment(response) {
    const customer = {
      intellipayResponse: response,
      merchant: {
        id: this.user.merchantId,
      },
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      amount: this.amount,
      utility: {
        id: this.utilityId,
      },
      reason: this.reason,
    };
    this.AdhocPaymentField.forEach((x, index) => {
      Object.assign(customer, { ["field" + (index + 1)]: x.FieldValue });
    });
    this.billerService.createAdhocPayment(customer).subscribe((res) => {
      if (res) {
        this.toaster.success("Adhoc payment successfull");
        this.spinner.hide();
        // this.router.navigate(["/biller-support/demand-receipt"], {
        //   queryParams: {
        //     merchantId: res.merchant.id,
        //     paymentId: res.id,
        //   },
        // });
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.amount = "";
        this.reason = "";
        this.AdhocPaymentField.forEach((x, index) => {
          x.FieldValue = "";
          this.AdhocPaymentField[index] = x;
        });
        const receipt = "/demand-receipt?merchantId=" + res.merchant.id + "&paymentId=" + res.id;
        window.open(receipt);
      }
    });
  }

  numberOnly(event): boolean {
    let value = event.target.value;
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
     let current: string = value;
      const position = event.target.selectionStart;
      const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
      if (next && !String(next).match(this.regex)) {
       event.preventDefault();
      }

  }
}
