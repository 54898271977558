import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { BillerService } from "../biller.service";
import { DemandPaymentReceipt } from "../_shared/demand-payment-receipt";
import { PaymentsService } from "app/payments/payments.service";
import { CommonService } from "app/_core/common.service";

@Component({
  selector: "app-demand-payment-receipt",
  templateUrl: "./demand-payment-receipt.component.html",
  styleUrls: ["./demand-payment-receipt.component.css"],
})
export class DemandPaymentReceiptComponent implements OnInit {
  paymentId: string;
  merchantId: string;
  private unsubscribe$ = new Subject<void>();
  demandReceipt: DemandPaymentReceipt;

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private billerservice: BillerService,
    private paymentService: PaymentsService,
    private commonService: CommonService
  ) {
    this.demandReceipt = new DemandPaymentReceipt();
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        tap((queryParams: Params) => {
          this.paymentId = queryParams["paymentId"];
          this.merchantId = queryParams["merchantId"];
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        () => {},
        (err) => {}
      );
    this.getPaymentReceipt(this.merchantId, this.paymentId);
  }

  /* get payment receipt */
  getPaymentReceipt(merchantId, paymentId) {
    this.spinner.show();
    this.billerservice.demandPaymentReceipt(merchantId, paymentId).subscribe(
      (res) => {
        this.demandReceipt = res;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  /* export payment receipt to pdf */
  exportToPDF(isDownload: boolean) {
    this.spinner.show();
    this.paymentService.exportAdhocPaymentToPdf(this.paymentId, this.merchantId).subscribe(
      (res) => {
        if (res) {
          if (isDownload) {
            this.downloadFile(res.result, this.demandReceipt.accountNumber, "application/pdf", "download");
            this.spinner.hide();
          } else {
            this.downloadFile(res.result, this.demandReceipt.accountNumber, "application/pdf", "print");
            this.spinner.hide();
          }
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  /* download pdf */
  downloadFile(data: string, accountNumber: string, fileType: any, type: string) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    if (type === "print") {
      window.location.href = url;
      this.spinner.hide();
    } else {
      const downloadPDF = document.createElement("a");
      downloadPDF.href = url;
      const today = new Date();
      const date = this.commonService.formatDate(today);
      // downloadPDF.download = accountNumber + '_' + date + '.pdf';
      downloadPDF.download = "AdhocPayment" + "_" + date + ".pdf";
      downloadPDF.click();
      this.spinner.hide();
    }
  }
}
