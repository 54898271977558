import { Injectable } from '@angular/core';
import { MerchantBill } from 'app/_shared/model/merchant-bill';
import { MerchantAutoPaymentFilter, MerchantBillFilter } from './_shared/model/bill-filter';
import { DataService } from 'app/_core/data.service';
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { Observable } from 'rxjs';
import { Utility } from 'app/_shared/model/utility';
import { BillViewModel } from './_shared/model/bill-view-model';
import { ConsumerBill } from 'app/_shared/model/consumer-bill';
import { AutoPayment } from 'app/reports/_shared/model/auto-payment';
import { EbillSubscribe } from 'app/reports/_shared/model/ebill-subscribe';
@Injectable({
  providedIn: 'root'
})
export class BillsService {
  constructor(private dataService: DataService) {}

  getBillList(
    merchantId: string,
    merchantBill: PaginationVM<MerchantBill, MerchantBillFilter>
  ): Observable<PaginationVM<MerchantBill, MerchantBillFilter>> {
    return this.dataService.post<
      PaginationVM<MerchantBill, MerchantBillFilter>,
      PaginationVM<MerchantBill, MerchantBillFilter>
    >(`merchant/Bill/${merchantId}`, merchantBill);
  }

  GetBill(
    billId: string,
    merchantId: string,
    utilityId: string,
    accountNumber: string
  ): Observable<BillViewModel> {
    return this.dataService.get(
      `bills/bill/${utilityId}/${merchantId}/${billId}/${accountNumber}`
    );
  }

  ExportToPDF(
    billId: string,
    merchantId: string,
    utilityId: string,
    accountNumber: string
  ): Observable<any> {
    return this.dataService.get(
      `bills/pdf/${utilityId}/${merchantId}/${billId}/${accountNumber}`
    );
  }

  viewPdfFromCloud(pdfName: string, containerName: string): Observable<any> {
    return this.dataService.get(
      `bills/getpdffromcloud/${pdfName}/${containerName}`
    );
  }

  getBill(consumerBill: ConsumerBill): Observable<ConsumerBill> {
    return this.dataService.get<ConsumerBill>(
      `bills/quickbills/${consumerBill.utilityId}/${consumerBill.merchantId}/${consumerBill.accountNumber}`
    );
  }

  GetBPXBill(token: string): Observable<any> {
    const dt = decodeURIComponent(token);
    return this.dataService.postDataAnonymous('bills/getBPXBill/', dt);
  }

  // 13 July 2022

  getAutoPaymentScheduleList(
    merchantId: string,
    merchantBill: PaginationVM<AutoPayment, MerchantAutoPaymentFilter>
  ): Observable<PaginationVM<AutoPayment, MerchantAutoPaymentFilter>> {
    return this.dataService.post<
      PaginationVM<AutoPayment, MerchantAutoPaymentFilter>,
      PaginationVM<AutoPayment, MerchantAutoPaymentFilter>
    >(`merchant/AutoPayments/${merchantId}`, merchantBill);
  }

  canCleAutoRecurringPayment(data: any): Observable<any> {
    return this.dataService.post('/merchant/AutoPayments/cancleautorecurringpayment', data);
  }

  rescheduleautorecurringpayment(data: any): Observable<any> {
    return this.dataService.post('/merchant/AutoPayments/rescheduleautorecurringpayment', data);
  }

  unregisterautorecurring(data: any): Observable<any> {
    return this.dataService.post('/merchant/AutoPayments/unregisterautorecurring', data);
  }

   //19 june 2023
   //account subscribe api
   addUtilityFromBiller(data: any): Observable<any> {
    return this.dataService.post('account/addutilityfrombiller', data);
  }

  //account and email api data
  getUserData(data: any): Observable<any> {
    return this.dataService.post('account/GetUserData', data);
  }

  //22 June 2023
  OptInForEbills(data:any, merchantId: string): Observable<any> {
    const methodName = `consumerDashboard/optine-bills?merchantId=${merchantId}`;
    return this.dataService.post(methodName, data);
  }

}
