import { LoginVM } from "./../_shared/model/login-vm";
import { RemoteValidation } from "app/_shared/model/remote-validation.model";
import { ConsumerDetails } from "./../_shared/model/consumer-details";
import { Injectable } from "@angular/core";
import { User } from "app/_shared/model/user.model";
import { DataService } from "app/_core/data.service";
import { Observable, BehaviorSubject } from "rxjs";
import { Login } from "app/_shared/model/login.model";
import { ChangePassword } from "app/_shared/model/change-password";
import { UtilityMaster } from "app/_shared/model/utility-master";
import { RegisterUtility } from "app/_shared/model/register-utility";
import { Utility } from "app/_shared/model/utility";
import { ConsumerUtility } from "app/_shared/model/consumer-utility";
import { UtilityVM } from "app/_shared/model/utility-vm";
import { NewUser } from "app/_shared/model/newUser.model";
import { ForgotPasswordViewModel } from "./_shared/model/forgot-password-view-model";
import { Consumer } from "./_shared/model/consumer";
//import { Statistics } from 'app/_shared/model/statistics';
import { PaymentSuccess } from "app/_shared/model/payment-success";
import { NewUtility } from "./_shared/model/new-utility";
import { Admin } from "./_shared/model/admin";
import { Merchant } from "./_shared/model/merchant";
import { ContactUs } from "app/_shared/model/contact-us";
import { MainComponent } from "app/layouts/main.component";
import { MerchantUser } from "./_shared/model/merchant-user";
import { MerchantUtilitySales } from "app/dashboard/_shared/model/merchant-utility-sales";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  private updateList = new BehaviorSubject(false);
  MerchantList = this.updateList.asObservable();
  user: User;
  private updateUserOnDashboard = new BehaviorSubject(false);
  UserUpdate = this.updateUserOnDashboard.asObservable();

  private updateMessageOnDashboard = new BehaviorSubject(false);
  MessageUpdate = this.updateMessageOnDashboard.asObservable();

  private showMessage = new BehaviorSubject(false);
  show = this.showMessage.asObservable();

  constructor(private dataService: DataService) {}

  login(user: LoginVM): Observable<any> {
    return this.dataService.post("account/token", user);
  }
  // login(user: Admin): Observable<any> {
  // 	return this.dataService.post('admin', user);
  // }

  updateMerchantList(isReload: boolean) {
    this.updateList.next(isReload);
  }

  sendOtp(email: string): Observable<boolean> {
    return this.dataService.get<boolean>("account/password/" + email + "/token");
  }

  // getStatistics(consumerId: string) {
  //   return this.dataService.get<Statistics>('consumerDashboard/' + consumerId + '/Statistics');
  // }

  CheckOTP(resetPassword: RemoteValidation): Observable<boolean> {
    return this.dataService.get<boolean>(`Consumer/${resetPassword.key}/${resetPassword.value}`);
  }

  resetPassword(model: ForgotPasswordViewModel): Observable<ForgotPasswordViewModel> {
    return this.dataService.post<ForgotPasswordViewModel, ForgotPasswordViewModel>("account/password/", model);
  }

  addUser(user: User): Observable<User> {
    return this.dataService.post<User, User>("Consumer/AddUser", user);
  }
  UpdateUser(merchant: Merchant): Observable<Merchant> {
    return this.dataService.post<Merchant, Merchant>("merchants/update", merchant);
  }
  // UpdateUser(consumer: Consumer): Observable<Consumer> {
  //   return this.dataService.post<Consumer, Consumer>('account/update', consumer);
  // }

  GetUser(email: string): Observable<Merchant> {
    return this.dataService.get<Merchant>("account/merchant/" + email);
  }

  GetMerchantUser(email: string): Observable<MerchantUser> {
    return this.dataService.get<MerchantUser>("account/merchant/profile/" + email);
  }

  // GetProfile(email: string): Observable<Consumer> {
  //   return this.dataService.get<Consumer>('account/profile/' + email);
  // }

  registerUser(newUser: NewUser): Observable<any> {
    return this.dataService.post("account", newUser);
  }

  sendRegistrationOtp(email: string): Observable<string> {
    return this.dataService.post<string, object>("account/" + email + "/token", {});
  }

  ChangePassword(changePassword: ChangePassword): Observable<any> {
    return this.dataService.put("account/merchantpassword", changePassword);
  }

  getMasterUtilities(): Observable<UtilityMaster[]> {
    // delete after register utility
    return this.dataService.get("Utility/GetMasterUtilities");
  }

  // registerConsumerUtility(utility: RegisterUtility, consumerId: string): Observable<RegisterUtility> {
  //   return this.dataService.post<RegisterUtility, RegisterUtility>('consumers/' + consumerId + '/utilities', utility);
  // }
  getRegisteredUtilities(consumerId): Observable<RegisterUtility[]> {
    return this.dataService.get<RegisterUtility[]>(`consumers/${consumerId}/utilities`);
    // return this.dataService.get('Utility/getRegisteredUtilities');
  }
  //coment 21/5
  // getMerchants(): Observable<Merchant[]> {
  //   return this.dataService.get('merchants');
  // }
  //  getUtilities(consumerId: string): Observable<NewUtility[]> {
  //    return this.dataService.get(`consumers/${consumerId}/utilities`);
  //  }

  // getUtilitiesFromMerchant(merchantId: string): Observable<Utility[]> {
  //   // delete after register utility
  //   return this.dataService.get<Utility[]>(`merchants/${merchantId}/utilities`);
  // }

  // EmailRemoteValidation(validationData: RemoteValidation): Observable<any> {
  // 	return this.dataService.post('Consumer/IsValidUser/', validationData);
  // }

  DeleteUtility(id: string, isActive: boolean): Observable<boolean> {
    return this.dataService.delete(`consumers/${id}/${isActive}`);
  }

  updateProfileOnNavbar(isReload: boolean) {
    this.updateUserOnDashboard.next(isReload);
  }

  updatePasswordMessage(isReload: boolean) {
    this.updateMessageOnDashboard.next(isReload);
  }

  showMessageOnLogin(isReload: boolean) {
    this.showMessage.next(isReload);
  }

  LogOut(sessionId: string): Observable<string> {
    return this.dataService.get<string>("account/logout/" + sessionId);
  }

  addPayment(paymentSuccess: PaymentSuccess): Observable<PaymentSuccess> {
    return this.dataService.post<PaymentSuccess, PaymentSuccess>("payments/addPayment", paymentSuccess);
  }

  registerUtility(consumerId: string, newUtility: NewUtility): Observable<any> {
    return this.dataService.post<any, {}>(`consumers/${consumerId}/utilities`, newUtility);
  }
  postContact(contactUs: ContactUs): Observable<ContactUs> {
    return this.dataService.post("account/contactUs", contactUs);
  }

  getBillerLogo(merchantId: string): Observable<any> {
    return this.dataService.get("merchants/logo/" + merchantId);
  }

  // //2 feb 2023
  //adhoc payment field api
  getUtilitiesFromMerchant(merchantId: string, isWantedTerminals: boolean): Observable<Utility[]> {
    return this.dataService.get<Utility[]>(`merchant/support/getbilltypeswithfields/${merchantId}/${isWantedTerminals}`);
  }

  //  addAdhocPayment(data: any): Observable<any> {
  //   return this.dataService.post('merchant/support/storeadhocpayment', data);
  // }
  merchantUtility(merchantId: string): Observable<Utility[]> {
    return this.dataService.get<Utility[]>(`merchants/${merchantId}/utilities`);
  }
}
