import { Injectable } from '@angular/core';
import { DataService } from 'app/_core/data.service';
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { MerchantPayment } from './_shared/model/merchant-payment';
import { MerchantPaymentFilter } from 'app/bills/_shared/model/bill-filter';
import { Observable } from 'rxjs';
import { Utility } from 'app/_shared/model/utility';
import { Receipt } from 'app/_shared/model/receipt-model';
import { Proxinization } from './_shared/proxinization';
import { ItemId } from 'app/_shared/model/item-id';


@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private dataService: DataService) { }

  getPaymentHistory(
    merchantId: string,
    merchantPayment: PaginationVM<MerchantPayment, MerchantPaymentFilter>
  ):Observable<PaginationVM<MerchantPayment, MerchantPaymentFilter>> {
    return this.dataService.post<PaginationVM<MerchantPayment, MerchantPaymentFilter>, PaginationVM<MerchantPayment, MerchantPaymentFilter>>(
      `merchant/payment/${merchantId}`,
      merchantPayment
    );
  }

  getACHPaymentHistory(
    merchantId: string,
    merchantPayment: PaginationVM<MerchantPayment, MerchantPaymentFilter>
  ):Observable<PaginationVM<MerchantPayment, MerchantPaymentFilter>> {
    return this.dataService.post<PaginationVM<MerchantPayment, MerchantPaymentFilter>, PaginationVM<MerchantPayment, MerchantPaymentFilter>>(
      `merchant/payment/ACH/${merchantId}`,
      merchantPayment
    );
  }

  getMastercardPaymentHistory(
    merchantId: string,
    merchantPayment: PaginationVM<MerchantPayment, MerchantPaymentFilter>
  ):Observable<PaginationVM<MerchantPayment, MerchantPaymentFilter>> {
    return this.dataService.post<PaginationVM<MerchantPayment, MerchantPaymentFilter>, PaginationVM<MerchantPayment, MerchantPaymentFilter>>(
      `merchant/mastercard/payment?merchantId=${merchantId}`,
      merchantPayment
    );
  }


  getUtilitiesFromConsumer(consumerId: string): Observable<Utility[]> {
    return this.dataService.get<Utility[]>(`utilities/merchant/${consumerId}`);
  }
  GetPaymentReceipt(paymentId: string, merchantId: string, utilityId: string): Observable<Receipt> {
    return this.dataService.get<Receipt>(`payments/getPayment/${paymentId}/${merchantId}/ ${utilityId}`);
  }

  ExportToPDF(paymentId: string, merchantId: string, utilityId: string, type: string): Observable<any> {
    return this.dataService.get(`payments/pdf/${paymentId}/${merchantId}/${utilityId}/${type}`);
  }

  PayUsingACH(proxynization: Proxinization): Observable<Receipt> {
		return this.dataService.post<Receipt, Proxinization>('payments/ChargeTheCard', proxynization);
  }


  getPaymentModes(merchantId: string, utilityId: string): Observable<string[]> {
    return this.dataService.get<string[]>(`payments/paymentmodes/${merchantId}/${utilityId}/`);
  }

  exportPaymentToExcel(merchantId,filter: MerchantPaymentFilter): Observable<MerchantPayment[]> {
    return this.dataService.post(`merchant/payment/export/${merchantId}`, filter);
  }
  getCardServiceProviders(merchantId: string, utilityId: string): Observable<string[]> {
    return this.dataService.get<string[]>(`payments/cardServiceProviders/${merchantId}/${utilityId}/`);
  }

  // --------------------------

  getBtxPaymentHistory(
    merchantId: string,
    merchantPayment: PaginationVM<MerchantPayment, MerchantPaymentFilter>
  ):Observable<PaginationVM<MerchantPayment, MerchantPaymentFilter>> {
    return this.dataService.post<PaginationVM<MerchantPayment, MerchantPaymentFilter>, PaginationVM<MerchantPayment, MerchantPaymentFilter>>(
      `merchant/payment/btxpayments/${merchantId}`,
      merchantPayment
    );
  }

  exportBtxPaymentToExcel(merchantId,filter: MerchantPaymentFilter): Observable<MerchantPayment[]> {
    return this.dataService.post(`merchant/payment/export/btxpayments/${merchantId}`, filter);
  }

  exportAdhocPaymentToPdf(paymentId: string, merchantId: string): Observable<any> {
    return this.dataService.get(`merchant/support/adhocpdf/${paymentId}/${merchantId}`);
  }

  // -------------- RPPS Pyment ---------------

  rppsPayments(
    merchantId: string,
    merchantPayment: PaginationVM<MerchantPayment, MerchantPaymentFilter>
  ):Observable<PaginationVM<MerchantPayment, MerchantPaymentFilter>> {
    return this.dataService.post<PaginationVM<MerchantPayment, MerchantPaymentFilter>, PaginationVM<MerchantPayment, MerchantPaymentFilter>>(
      `merchant/payment/rpps/${merchantId}`,
      merchantPayment
    );
  }


}
