import { Component, OnInit } from '@angular/core';
import { AccountService } from 'app/account/account.service';
import { User } from 'app/_shared/model/user.model';
import { NgForm } from '@angular/forms';
import { Login } from 'app/_shared/model/login.model';
import { AutoLogoutService } from 'app/_core/auto-logout.service';
import { MaxLengthConstants, Constant } from 'app/_shared/constant';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-empty',
  templateUrl: './account.layout.component.html'
})
export class AccountLayoutComponent implements OnInit {
  user: User;
  data: Login;
  public loading = false;

  userNameLength = MaxLengthConstants.UserName;
  userNamePattern = Constant.USERNAME;
  passwordLength = MaxLengthConstants.Password;
  passwordPattern = Constant.PASSWORD_REGEXP;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private autoLogoutService: AutoLogoutService,
  ) {
    this.user = new User();
    this.data = new Login();
    autoLogoutService.eventCallback$.subscribe(data => {
      this.logOut();
    });
  }

  ngOnInit() {
    $('.full-height').height($(window).height());
  }


  logOut() {
    sessionStorage.clear();
    this.router.navigate(['']);
  }

  forgotPassword() {
    this.router.navigate(['/account/forgot-password'], {});
  }

  onAnchorClick() {
    this.route.fragment.subscribe(f => {
      const element = document.querySelector('#' + f);
      if (element) {
        element.scrollIntoView(true);
      }
    });
  }
}
