import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
//import { NewCustomer } from '../_shared/new-customer';
import { NgForm } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ToastrService } from "ngx-toastr";
//import { BillerService } from '../biller.service';
import { CommonService } from "app/_core/common.service";
import { UtilitiesService } from "app/utilities/utilities.service";
import { MerchantUtilitySales } from "app/dashboard/_shared/model/merchant-utility-sales";
import { AccountService } from "app/account/account.service";
import { Utility } from "app/_shared/model/utility";
import { PaginationVM } from "app/_shared/model/pagination.model";
//import { Customers, CustomerFilter } from '../_shared/customers';
import { takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { ExportToExcelService } from "app/_core/export-to-excel.service";
declare var intellipay: any;
import * as _ from "underscore";
import { Constant } from "app/_shared/constant";
import { NewCustomer } from "../new-customer";
import { BillerService } from "app/usermanagement/biller.service";
import { Customers, CustomerFilter } from "app/usermanagement/_shared/customers";
import { MerchantUser } from "app/usermanagement/_shared/merchant-user";
import { log } from "console";

@Component({
  selector: "app-adhoc_payment_list",
  templateUrl: "./adhoc_payment_list.component.html",
  styleUrls: ["./adhoc_payment_list.component.css"],
})
export class AdhocPaymentListComponent implements OnInit {
  @ViewChild("payNowDashboard", { static: false }) payNowDashboard: ElementRef;
  @ViewChild("payNowDashboard1", { static: false }) payNowDashboard1: ElementRef;
  @ViewChild("payNowDashboard2", { static: false }) payNowDashboard2: ElementRef;
  @ViewChild("payNowDashboard3", { static: false }) payNowDashboard3: ElementRef;

  private unsubscribe$ = new Subject<void>();
  customer: NewCustomer;
  openForm: boolean;
  merchantId: string;
  utilities: Utility[];
  paginationVM = new PaginationVM<Customers, CustomerFilter>();
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  pageNumber: number;
  customers: Customers[];
  customerFilter: CustomerFilter;
  today = new Date();
  fToday = new Date();
  toDate: Date;
  IsSearching: boolean;
  isAdhocPayment: boolean;
  adhocPayment: boolean;
  urls: string;
  emailPattern = Constant.EMAIL_REGEXP;
  amountPattern = Constant.AMOUNT;
  interval: any;
  AdhocPaymentField: any;
  utilityId: any;
  utilityName: any;
  user: any = MerchantUser;
  merchantUser: MerchantUser;
  headers: any;
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private billerService: BillerService,
    private commonService: CommonService,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private exportToExcelService: ExportToExcelService
  ) {
    //this.merchantId = this.commonService.getMerchantUserId();
    this.user = this.commonService.getMerchanUsertData();
    this.customer = new NewCustomer();
    this.customers = new Array<Customers>();
    this.customerFilter = new CustomerFilter();
    this.openForm = false;
    this.IsSearching = true;
    this.utilities = new Array<Utility>();
    this.customerFilter.from = this.firstDayOfMonthDateFormat();
    this.customerFilter.to = new Date();
    this.paginationVM = <PaginationVM<Customers, CustomerFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.customers,
      filters: this.customerFilter,
    };
    this.adhocPayment = false;
  }

  ngOnInit() {
    if (this.router.url.includes("biller-support/add-adhoc-payment-list")) {
      this.adhocPayment = true;
    }

    this.getUtilities();

    this.activatedRoute.queryParams
      .pipe(
        tap((queryParams: Params) => {
          this.isAdhocPayment = queryParams["isAdhoc"];
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        () => {
          this.openForm = this.isAdhocPayment;
          this.IsSearching = !this.isAdhocPayment;
        },
        (err) => {}
      );

    // this.callIntellipay(this.merchantId);
    // this.getNewCustomers();
  }

  onValueChange(fromDate) {
    if (fromDate) {
      this.fToday = fromDate;
    } else {
      this.fToday = this.today;
    }
  }

  firstDayOfMonthDateFormat() {
    const t = new Date();
    return new Date(t.getFullYear(), t.getMonth(), 1, 0, 0, 0, 0);
  }

  /*Get list of utilities registered for perticular merchant*/
  //  getUtilities() {
  //   this.spinner.show();
  //   this.accountService.getUtilitiesFromMerchant(this.merchantId).subscribe(
  //     (res: any) => {
  //       if (res) {
  //         this.utilities = res.Fields;
  //         this.customer.utility.id = this.utilities[0].id;
  //         this.getNewCustomers();
  //         this.spinner.hide();
  //       }
  //     },
  //     err => {
  //       this.spinner.hide();
  //     }
  //   );
  // }
  getUtilities() {
    this.spinner.show();
    // const data = {
    //   fromDate: this.commonService.currentDateFormat(
    //     this.customerFilter.from.getFullYear(),
    //     this.customerFilter.from.getMonth(),
    //     this.customerFilter.from.getDate(),
    //     0,
    //     0,
    //     0,
    //     0
    //   ),
    //   toDate: this.commonService.currentDateFormat(
    //     this.customerFilter.to.getFullYear(),
    //     this.customerFilter.to.getMonth(),
    //     this.customerFilter.to.getDate(),
    //     23,
    //     59,
    //     0,
    //     0
    //   ),
    // };
    this.accountService.getUtilitiesFromMerchant(this.user.merchantId, false).subscribe(
      (res: any) => {
        if (res) {
          this.utilities = res.Fields;
          this.AdhocPaymentField = res.Fields[0].AdhocPaymentFields;

          const utilityId = sessionStorage.getItem("billTypeId");
          this.utilityId = utilityId != null || utilityId != undefined ? utilityId : res.Fields[0].Id;
          // this.utilityName = res.Fields[0].Name;
          sessionStorage.removeItem("billTypeId");
          this.getNewCustomers();
          this.headers = res.Fields.find((x) => x.Id == this.utilityId).Headers;
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  getNewCustomers() {
    this.spinner.show();
    this.paginationVM.filters.utilityId = this.utilityId;
    this.billerService.getAdhocPayment(this.user.merchantId, this.paginationVM).subscribe(
      (res) => {
        if (res) {
          this.paginationVM = res;
          console.log("pagination", this.paginationVM);
          this.customers = new Array<Customers>();
          this.customers = this.paginationVM.records;
          if (this.increase) {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          } else if (this.decrease) {
            this.showingPage = this.showingPage - this.oldPageLength;
          } else {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          }
          this.oldPageLength = this.paginationVM.records.length;
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
          this.increase = false;
          this.decrease = false;
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  onChangeOfUtility(utilityId: string) {
    this.utilities.forEach((element: any) => {
      var a: any = ([] = this.utilities.filter((element1: any) => element1.Id == utilityId));
      this.headers = a[0].Headers;
    });
    // this.paginationVM = <PaginationVM<Customers, CustomerFilter>>{
    //   pageNumber: 0,
    //   pageSize: 10,
    //   totalCount: 0,
    //   sortBy: "Id",
    //   sortType: "desc",
    //   records: this.customers,
    //   filters: this.customerFilter,
    // };
    this.showingPage = 0;
    this.searchCustomers();
  }
  searchCustomers() {
    this.paginationVM = <PaginationVM<Customers, CustomerFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.customers,
      filters: this.customerFilter,
    };
    if (this.customerFilter.from != null) {
      // tslint:disable-next-line: max-line-length
      this.customerFilter.from = this.commonService.currentDateFormat(
        this.customerFilter.from.getFullYear(),
        this.customerFilter.from.getMonth(),
        this.customerFilter.from.getDate(),
        0,
        0,
        0,
        0
      );
    }
    if (this.customerFilter.to != null) {
      // tslint:disable-next-line: max-line-length
      this.customerFilter.to = this.commonService.currentDateFormat(
        this.customerFilter.to.getFullYear(),
        this.customerFilter.to.getMonth(),
        this.customerFilter.to.getDate(),
        23,
        59,
        0,
        0
      );
    }
    this.showingPage = 0;
    this.paginationVM.filters = this.customerFilter;
    this.getNewCustomers();
  }

  resetSearch(form: NgForm) {
    this.customerFilter = new CustomerFilter();
    this.customers = [];
    this.showingPage = 0;
    form.resetForm();
    this.paginationVM = <PaginationVM<Customers, CustomerFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.customers,
      filters: this.customerFilter,
    };
    this.paginationVM.filters = this.customerFilter;
    this.customerFilter.from = this.firstDayOfMonthDateFormat();
    this.customerFilter.to = new Date();
    this.getUtilities();
  }

  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getNewCustomers();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getNewCustomers();
    }
  }

  cancel(form: NgForm) {
    form.resetForm();
    this.adhocPayment = true;
    this.IsSearching = true;
    this.openForm = false;
    // this.callIntellipay(this.merchantId);
  }

  openCustomerForm(isOpen: boolean) {
    this.openForm = isOpen;
    this.IsSearching = !isOpen;
    this.adhocPayment = false;
    this.getUtilities();
    // this.callIntellipay(this.merchantId);
  }

  addCustomer() {
    this.payNowDashboard1.nativeElement.attributes[7].nodeValue = this.customer.amount;
    this.payNowDashboard2.nativeElement.attributes[7].nodeValue = this.customer.accountNumber + "-" + this.customer.prefix;
    this.payNowDashboard3.nativeElement.attributes[7].nodeValue ='Adhoc';

    this.payNowDashboard.nativeElement.click();
  }

  reset(form: NgForm) {
    form.resetForm();
    this.getUtilities();
  }

  callIntellipay(form: NgForm, customer: Customers) {
    if (form.valid) {
      this.spinner.show();
      this.billerService.lightboxEmvIn(this.merchantId, customer.utility.id).subscribe((res) => {
        if (res) {
          const rg = document.createRange();
          document.documentElement.appendChild(rg.createContextualFragment(res.result));
          intellipay.setItemLabel("account", "Account Number");
          intellipay.setItemLabel("button", "Pay Now"); //Set the pay button text
          intellipay.disable("email");
          intellipay.initialize();
          // this.spinner.hide();
          const _this = this;
          intellipay.runOnApproval(function (response) {
            _this.storeNewCustomerPayment(JSON.stringify(response));
          });
          // setTimeout(function () {
          //   _this.addCustomer();
          //   _this.spinner.hide();
          // }, 1000);
          this.interval = setInterval(() => {
            if (intellipay.isReady) {
              clearInterval(this.interval);
              _this.addCustomer();
            }
          }, 2000);
        }
      });
    }
  }

  storeNewCustomerPayment(response) {
    this.customer.intellipayResponse = response;
    this.customer.merchant.id = this.merchantId;
    this.billerService.storecustomerIntelliPayResponse(this.customer).subscribe((res) => {
      if (res) {
        this.paginationVM.pageNumber = 0;
        this.IsSearching = true;
        this.openForm = false;
        this.customer = new NewCustomer();
        this.toaster.success("Adhoc payment successfull");
        this.router.navigate(["/biller-support/demand-receipt"], {
          queryParams: {
            merchantId: res.merchant.id,
            paymentId: res.id,
          },
        });
      }
    });
  }

  showPaymentReceipt(customer: Customers) {
    const receipt = "/payment/demand-receipt?merchantId=" + customer.merchant.id + "&paymentId=" + customer.id;
    window.open(receipt);
  }

  exportToExcel() {
    this.spinner.show();
    if (this.customerFilter.from != null) {
      // tslint:disable-next-line: max-line-length
      this.customerFilter.from = this.commonService.currentDateFormat(
        this.customerFilter.from.getFullYear(),
        this.customerFilter.from.getMonth(),
        this.customerFilter.from.getDate(),
        0,
        0,
        0,
        0
      );
    }
    if (this.customerFilter.to != null) {
      // tslint:disable-next-line: max-line-length
      this.customerFilter.to = this.commonService.currentDateFormat(
        this.customerFilter.to.getFullYear(),
        this.customerFilter.to.getMonth(),
        this.customerFilter.to.getDate(),
        23,
        59,
        0,
        0
      );
    }
    this.billerService.getAdhocPayment(this.user.merchantId, this.paginationVM).subscribe(
      (res: any) => {
        if (res.records.length > 0) {
          res.records.forEach((item) => {
            const paymentDate = new Date(item.paymentDate);
            item.paymentDate = this.commonService.formatDueDate(paymentDate);
          });
          const data = _.map(res.records, function (o) {
            return _.pick(o, "transactionId", "field1", "firstName", "lastName", "email", "amount", "paymentDate", "reason");
          });
          const labels = "Transaction ref, Account Number, First Name, Last Name, Email, Amount Paid ($),Payment Date,Reason";
          const today = new Date();
          const date = this.commonService.formatDate(today);
          this.exportToExcelService.download(data, "Report_" + date, labels);
          this.toaster.success("The request data-sheet created successfully.", "");
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  //   if(this.adhocPayment){
  //   this.router.navigate(['/biller-support/demand-receipt'], {
  //     queryParams: {
  //       merchantId: customer.merchant.id,
  //       paymentId: customer.id
  //     }
  //   });
  // } else {
  //   this.router.navigate(['/report/demand-receipt'], {
  //     queryParams: {
  //       merchantId: customer.merchant.id,
  //       paymentId: customer.id
  //     }
  //   });
  // }
}
