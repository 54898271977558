import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root"
})
export class HttpInterceptorService {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          sessionStorage.clear();
          this.router.navigate(['/']);
        //  const token = sessionStorage.getItem('AccessToken')
        //return next.handle(this.addToken(request, token));
        }
        return throwError(err.statusText);
      })
    );
  }

  // addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
  //   return req.clone({
  //     headers: new HttpHeaders()
  //       .set("Authorization", `Bearer ${sessionStorage.getItem('AccessToken')}`)
  //       .set("Content-Type", "application/json")
  //       .set("Cache-control", "no-cache")
  //       .set("Cache-control", "no-store")
  //       .set("Expires", "0")
  //       .set("Pragma", "no-cache")
  //   });
  // }
}
