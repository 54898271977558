export class RppsSummary {
    billType: string;
    inputFile: string;
    inputFileReceivedOn: string;
    outputFile: string;
    processedDate: string;
    status: string;

    constructor() {
        this.billType = null;
        this.inputFile = null;
        this.inputFileReceivedOn = null;
        this.outputFile = null;
        this.processedDate = null;
        this.status = null;
    }
}

export class RppsSummaryFilter{
    utilityId: string;
    from: Date;
    to: Date;
    constructor(){
        this.utilityId = null
        this.from = null;
        this.to = null;
    }
}
