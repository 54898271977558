import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { StaticComponent } from './static.component';
import { RouteGaurdService } from 'app/_core/route-gaurd.service';
import { AccountLayoutComponent } from './account.layout.component';
// import { StaticBodyComponent } from './static-body/static-body.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: StaticComponent
      },
      {
        path: 'main',
        canActivate: [RouteGaurdService],
        component: MainComponent
      },
      {
        path: 'empty',
        component: AccountLayoutComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule {}
