export class Constant {
  public static CURRENCY = 'USD'; //  For Dollar set it to  'USD' and For Rupee set it to 'INR'
  public static DATE_FORMAT = 'dd-MM-y';
  public static DATE_FORMAT_DATEPICKER = 'DD/MM/YYYY';
  public static DATE_FORMAT_IMPRESSION = 'dd-MM-y hh:mm:ss';
  public static USERNAME = '^[a-zA-Z0-9!#$%&@_.-]+[\']*(s{0,1}[a-zA-Z0-9!#$%&@_.-])*$';
  public static ADDRESS = '^[a-zA-Z0-9#&/=_`,.-]+[\']*(s{0,1}[a-zA-Z0-9#&/_`., -])*$';
  public static ADDRESSEE_NAME = '^[a-zA-Z0-9!#$%&/@=_`|~.,-]+[\']*(s{0,1}[a-zA-Z0-9!#$%&/@=_`|~., -])*$'; // /^\S*$/;
  public static DateFormat = 'MM/dd/yyyy';
  public static EMAIL_REGEXP = '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';
  public static ACCOUNT_REGEXP = '^[a-zA-Z0-9]{3}[-][a-zA-Z0-9]{3}$';
  public static NAME_REGEXP = '^[a-zA-Z]+[\']*(s{0,1}[a-zA-Z ])*$';
  public static NUMBER_REGEXP = '^[0-9]+$';
  public static ALPHANUMERIC_REGEXP = '^[a-zA-Z0-9_]*$';
  public static JOB_DESCRIPTION = '^[a-zA-Z0-9-]+[\']*(s{0,1}[a-zA-Z0-9 -])*$';
  public static PHONE_REGEXP = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
  // public static PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/
  public static PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z0-9\S]{8,15}$/;
  // /^[a-zA-Z0-9!#$%&/\@=?^_`{|}~.]{8,15}$/;
  public static MONTH_REGEX = '^(0?[1-9]|1[012])$';
  public static COMMA_SEPERATE_EMAILS = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
  public static AMOUNT = /^[+]?([1-9]+\.?[0-9]*|\.[0-9]+)$/;



  public static ZIP_REGEXP = '^[0-9]{5}(-[0-9]{4})?$';
  public static FAX_REGEXP = '^(?=(?:D*d){10,12}D*$)[0-9 -()\\/]{1,16}$';
  public static WEBSITE_REGEXP = '^([a-zA-Z0-9]+(.[a-zA-Z0-9]+)+.*)$';
  public static FADT_REGEXP = /^[FADT]{65}$/;

  public static URL = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
}

export class MaxLengthConstants {
  public static Name = 25;
  public static AddresseeName = 50;
  public static CustomerName = 50;
  public static CompanyName = 25;
  public static PropertyName = 25;
  public static Address = 50;
  public static Street = 25;
  public static City = 15;
  public static State = 15;
  public static Email = 50;
  public static Phone = 15;
  public static Zip = 10;
  public static Password = 15;
  public static OTP = 8;
  public static CouponCod = 8;
  public static UserName = 20;
  public static Website = 100;
  public static Fax = 50;
  public static description = 100;
  public static FADT = 65;
}
