import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { AccountService } from "app/account/account.service";
import { UtilitiesService } from "app/utilities/utilities.service";
import { CommonService } from "app/_core/common.service";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { Utility } from "app/_shared/model/utility";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { RppsService } from "../rpps.service";
import { SummaryTimelineComponent } from "../summary-timeline/summary-timeline.component";
import { RppsSummary, RppsSummaryFilter } from "../_shared/rpps-summary";

@Component({
  selector: "app-rpps-logs",
  templateUrl: "./rpps-logs.component.html",
})
export class RppsLogsComponent implements OnInit {
  paginationVM = new PaginationVM<RppsSummary, RppsSummaryFilter>();
  logs: RppsSummary[];
  rppsLogsFilter: RppsSummaryFilter;
  merchantId: string;
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  pageNumber: number;
  utilities: any;
  fToday = new Date();
  today = new Date();
  reloadPage: boolean = false;
  isRpps: boolean = false;
  constructor(
    private rppsService: RppsService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private router: Router,
    private accountService: AccountService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService
  ) {
    this.logs = new Array<RppsSummary>();
    this.rppsLogsFilter = new RppsSummaryFilter();
    // this.rppsLogsFilter.from = this.commonService.currentDateFormat(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(),0,0,0,0);
    // this.rppsLogsFilter.to = this.commonService.currentDateFormat(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(),23,59,0,0);
    this.paginationVM = <PaginationVM<RppsSummary, RppsSummaryFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.logs,
      filters: this.rppsLogsFilter,
    };
    this.utilities = new Array<Utility[]>();
    this.merchantId = this.commonService.getMerchantUserId();
    this.isRpps = false;
  }

  ngOnInit() {
    if (this.router.url === "/rpps") {
      this.isRpps = true;
    }

    this.getUtilities();
    // this.getRppsLogs();
  }

  getUtilities() {
    this.spinner.show();
    this.accountService.merchantUtility(this.merchantId).subscribe((res) => {
      if (res) {
        this.spinner.hide();
        this.utilities = res;
        this.rppsLogsFilter.utilityId = this.utilities[0].id;
        this.getRppsLogs();
      }
      (err) => {
        this.spinner.hide();
      };
    });
  }

  onValueChange(fromDate) {
    if (fromDate) {
      this.fToday = fromDate;
    } else {
      this.fToday = this.today;
    }
  }

  getRppsLogs(): void {
    this.spinner.show();
    this.rppsService.getRppsLogs(this.merchantId, this.paginationVM).subscribe((res) => {
      if (res) {
        this.paginationVM = res;
        this.logs = new Array<RppsSummary>();
        this.logs = this.paginationVM.records;
        if (this.increase) {
          this.showingPage = this.showingPage + this.paginationVM.records.length;
        } else if (this.decrease) {
          this.showingPage = this.showingPage - this.oldPageLength;
        } else if (this.reloadPage) {
          //this.showingPage = this.showingPage - this.oldPageLength;
        } else {
          this.showingPage = this.showingPage + this.paginationVM.records.length;
        }
        this.oldPageLength = this.paginationVM.records.length;
        this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
        this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
        this.increase = false;
        this.decrease = false;
        this.spinner.hide();
      }
      (err) => {
        this.spinner.hide();
      };
    });
  }

  search(): void {
    this.spinner.show();
    if (this.rppsLogsFilter.from != null) {
      this.rppsLogsFilter.from = this.commonService.currentDateFormat(
        this.rppsLogsFilter.from.getFullYear(),
        this.rppsLogsFilter.from.getMonth(),
        this.rppsLogsFilter.from.getDate(),
        this.rppsLogsFilter.from.getHours(),
        this.rppsLogsFilter.from.getMinutes(),
        0,
        0
      );
    }
    if (this.rppsLogsFilter.to != null) {
      this.rppsLogsFilter.to = this.commonService.currentDateFormat(
        this.rppsLogsFilter.to.getFullYear(),
        this.rppsLogsFilter.to.getMonth(),
        this.rppsLogsFilter.to.getDate(),
        this.rppsLogsFilter.to.getHours(),
        this.rppsLogsFilter.to.getMinutes(),
        23,
        59
      );
    }
    this.showingPage = 0;
    this.paginationVM = <PaginationVM<RppsSummary, RppsSummaryFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.logs,
      filters: this.rppsLogsFilter,
    };
    this.paginationVM.filters = this.rppsLogsFilter;
    this.getRppsLogs();
  }

  reset(form: NgForm): void {
    this.rppsLogsFilter = new RppsSummaryFilter();
    this.logs = [];
    this.showingPage = 0;
    form.resetForm();
    this.paginationVM = <PaginationVM<RppsSummary, RppsSummaryFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.logs,
      filters: this.rppsLogsFilter,
    };
    this.rppsLogsFilter.utilityId = this.utilities[0].id;
    this.getUtilities();
  }

  reload(): void {
    this.reloadPage = true;
    this.getUtilities();
  }

  increasePageNumber(): void {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getRppsLogs();
    }
  }

  decreasePageNumber(): void {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getRppsLogs();
    }
  }

  review(rppsid: string) {
    if (this.router.url === "/report/bankbillpay") {
      this.router.navigate(["/report/review"], {
        queryParams: {
          rppsid: rppsid,
          billTypeId: this.rppsLogsFilter.utilityId,
        },
      });
    } else {
      this.router.navigate(["/rpps/review"], {
        queryParams: {
          rppsid: rppsid,
          billTypeId: this.rppsLogsFilter.utilityId,
        },
      });
    }
  }

  download(docId: string): void {
    this.spinner.show();
    this.rppsService.download(this.merchantId, this.rppsLogsFilter.utilityId, docId).subscribe((res) => {
      if (res) {
        const decode = atob(res.fileBase64Data);

        const file = new window.Blob([decode], { type: "text/txt" });

        const downloadAncher = document.createElement("a");
        downloadAncher.style.display = "none";

        const fileURL = URL.createObjectURL(file);
        downloadAncher.href = fileURL;
        downloadAncher.download = res.fileName;
        downloadAncher.click();
        this.spinner.hide();
      }
      (err) => {
        this.spinner.hide();
      };
    });
    // this.spinner.hide();
  }

  openTimeline(docId: string) {
    const initialState = {
      merchantId: this.merchantId,
      billTypeId: this.rppsLogsFilter.utilityId,
      docId: docId,
    };
    this.bsModalRef = this.modalService.show(SummaryTimelineComponent, {
      initialState,
      class: "modal-lg",
      backdrop: "static",
    });
    this.bsModalRef.content.closeBtnName = "Discard";
  }

  // formatDateYYYY_MM_DD(value): string {
  //   const date = new Date(value);
  //   const dd = date.getDate();
  //   const mm = date.getMonth() + 1;
  //   const yyyy = date.getFullYear();
  //   const hh = date.getHours();
  //   const min = date.getMinutes();
  //   const sec = date.getSeconds();
  //   return yyyy + '_' + this.getValue(mm) + '_' + this.getValue(dd) + '_' + this.getValue(hh) + this.getValue(mm) + this.getValue(sec);
  // }

  // getValue(value: number): string {
  //   return value.toString().length === 2 ? value.toString() : "0" + value.toString();
  // }
}
