import { EmptyLayoutComponent } from './layouts/empty-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StaticComponent } from './layouts/static.component';
import { MainComponent } from './layouts/main.component';
import { AccountLayoutComponent } from './layouts/account.layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout.component';
import { EmptyWizardComponent } from './layouts/empty-wizard.component';
import { BillerSupportLayoutComponent } from './layouts/biller-support-layout.component';
import { RppsLayoutComponent } from './layouts/rpps-layout.component';
import { PaymentReceiptComponent } from './payments/payment-receipt/payment-receipt.component';
import { DemandPaymentReceiptComponent } from './usermanagement/demand-payment-receipt/demand-payment-receipt.component';
const routes: Routes = [
  // {
  // 	path: '',
  // 	component: StaticComponent,
  // 	data: { title: 'Static Page' }
  // },
  {
    path: '',
    component: AccountLayoutComponent,
    data: { title: 'Main Page' },
    children: [
      {
        path: '',
        loadChildren: './account/account.module#AccountModule'
      }
    ]
  },
  {
    path: '',
    component: BillerSupportLayoutComponent,
    data: { title: 'Biller Layout' },
    children: [
      {
        path: 'biller-support',
        loadChildren:
          './usermanagement/usermanagement.module#UsermanagementModule'
      }
    ]
  },

  {
    path: '',
    component: RppsLayoutComponent,
    data: { title: 'RPPS Layout' },
    children: [
      {
        path: 'rpps',
        loadChildren:
          './rpps/rpps.module#RppsModule'
      }
    ]
  },

  {
    path: '',
    component: MainComponent,
    data: { title: 'Main Page' },
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'usermanagement',
        loadChildren:
          './usermanagement/usermanagement.module#UsermanagementModule'
      },
      {
        path: 'master-card',
        loadChildren: './master-card/mastercard.module#MastercardModule'
      },
      {
        path: 'utilities',
        loadChildren: './utilities/utilities.module#UtilitiesModule'
      },
      {
        path: 'payments',
        loadChildren: './payments/payments.module#PaymentsModule'
      },
      {
        path: 'bills',
        loadChildren: './bills/bills.module#BillsModule'
      },
      {
        path: 'registered-consumers',
        loadChildren:
          './registered-consumers/registered-consumers.module#RegisteredConsumersModule'
      },
      {
        path: 'report',
        loadChildren: './reports/reports.module#ReportsModule'
      },
      {
        path: 'summary',
        loadChildren: './summary/summary.module#SummaryModule'
      }
    ]
  },

  {
    path: '',
    component: EmptyLayoutComponent,
    data: { title: '' },
    children: [
      {
        path: 'bill',
        loadChildren: './bills/bills.module#BillsModule'
      },
      {
        path: 'payment',
        loadChildren: './payments/payments.module#PaymentsModule'
      },
      {
        path: 'county-home',
        loadChildren: './county/county.module#CountyModule'
      },
      {
        path: 'reports',
        loadChildren: './reports/reports.module#ReportsModule'
      },
    ]
  },

  {
    path: '',
    component: EmptyWizardComponent,
    data: { title: '' },
    children: [
      {
        path: 'preview',
        loadChildren: './empty-wizard/empty-wizard.module#EmptyWizardModule'
      }
    ]
  },
  {
    path: "demand-receipt",
    component: DemandPaymentReceiptComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
