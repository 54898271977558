import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Merchant } from "app/account/_shared/model/merchant";
import { CommonService } from "app/_core/common.service";
import { PaymentsService } from "../payments.service";
import { MerchantPayment } from "../_shared/model/merchant-payment";
import { MerchantPaymentFilter } from "app/bills/_shared/model/bill-filter";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { MerchantUser } from "app/account/_shared/model/merchant-user";
import { Utility } from "app/_shared/model/utility";
import { NgForm } from "@angular/forms";
import { Constant } from "app/_shared/constant";
import { UtilitiesService } from "app/utilities/utilities.service";
import { MerchantUtilitySales } from "app/dashboard/_shared/model/merchant-utility-sales";
import { NgxSpinnerService } from "ngx-spinner";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { ItemId } from "app/_shared/model/item-id";
import { ExportToExcelService } from "app/_core/export-to-excel.service";
import { ToastrService } from "ngx-toastr";
import * as _ from "underscore";
import { AccountService } from "app/account/account.service";

@Component({
  selector: "app-btx-payments",
  templateUrl: "./btx-payments.component.html",
  styleUrls: ["./btx-payments.component.css"],
})
export class BtxPaymentsComponent implements OnInit {
  merchantId: string;
  urls: string;
  pageNumber: number;
  merchantPaymentFilter: MerchantPaymentFilter;
  paymentHistoryList: MerchantPayment[];
  paginationVM = new PaginationVM<MerchantPayment, MerchantPaymentFilter>();
  today = new Date();
  fToday = new Date();
  toDate: Date;
  minDate: Date;
  consumerDetails: MerchantPayment;
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  utilities: Utility[];
  pageSize: number;
  ismeridian: boolean;
  constructor(
    private paymentsService: PaymentsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private exportToExcelService: ExportToExcelService,
    private toaster: ToastrService,
    private accountService: AccountService
  ) {
    this.ismeridian = false;
    this.paymentHistoryList = [];
    this.merchantPaymentFilter = new MerchantPaymentFilter();
    this.pageNumber = 1;
    this.consumerDetails = new MerchantPayment();
    this.merchantId = this.commonService.getMerchantUserId();
    this.toDate = this.minDate;
    this.utilities = new Array<Utility>();
    // this.merchantPaymentFilter.from =  this.currentDateFormat(0, 1, 0, 0);
    // this.merchantPaymentFilter.to =  this.currentDateFormat(23, 59, 0, 0);
    this.merchantPaymentFilter.from = this.commonService.currentDateFormat(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0,
      0
    );
    // tslint:disable-next-line: max-line-length
    this.merchantPaymentFilter.to = this.commonService.currentDateFormat(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      23,
      59,
      0,
      0
    );
    this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.paymentHistoryList,
      filters: this.merchantPaymentFilter,
    };
  }

  ngOnInit() {
    this.getUtilities();
  }

  currentDateFormat(h: number, m: number, s: number, ms: number) {
    const date = new Date();
    date.setHours(h);
    date.setMinutes(m);
    date.setSeconds(s);
    date.setMilliseconds(ms);
    return date;
  }

  /*Get list of utilities registered for perticular merchant*/
  getUtilities() {
    this.spinner.show();
    this.accountService.merchantUtility(this.merchantId).subscribe(
      (res) => {
        if (res) {
          this.utilities = res;
          this.merchantPaymentFilter.utilityId = this.utilities[0].id;
          this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
            pageNumber: 0,
            pageSize: 10,
            // pageSize: this.pageSize,
            totalCount: 0,
            sortBy: "Id",
            sortType: "desc",
            records: this.paymentHistoryList,
            filters: this.merchantPaymentFilter,
          };
          this.getPayments();
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  /* get payment list */
  getPayments() {
    this.spinner.show();
    this.paginationVM.filters.paymentChannel = "BillTrax";
    this.paymentsService.getBtxPaymentHistory(this.merchantId, this.paginationVM).subscribe(
      (res) => {
        this.paymentHistoryList = [];
        if (res) {
          this.paginationVM = res;
          this.paymentHistoryList = new Array<MerchantPayment>();
          this.paymentHistoryList = this.paginationVM.records;
          if (this.increase) {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          } else if (this.decrease) {
            this.showingPage = this.showingPage - this.oldPageLength;
          } else {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          }
          this.oldPageLength = this.paginationVM.records.length;
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
          this.increase = false;
          this.decrease = false;
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getPayments();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getPayments();
    }
  }

  /* reset payment foorm */
  reset(form: NgForm) {
    this.merchantPaymentFilter = new MerchantPaymentFilter();
    this.paymentHistoryList = [];
    this.showingPage = 0;
    form.resetForm();
    this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
      pageNumber: 0,
      pageSize: 10,
      // pageSize: this.pageSize,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.paymentHistoryList,
      filters: this.merchantPaymentFilter,
    };
    // this.merchantPaymentFilter.from =  this.currentDateFormat(0, 1, 0, 0);
    // this.merchantPaymentFilter.to =  this.currentDateFormat(23, 59, 0, 0);
    this.merchantPaymentFilter.from = this.commonService.currentDateFormat(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0,
      0
    );
    // tslint:disable-next-line: max-line-length
    this.merchantPaymentFilter.to = this.commonService.currentDateFormat(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      23,
      59,
      0,
      0
    );
    this.merchantPaymentFilter.utilityId = this.utilities[0].id;
    this.merchantPaymentFilter.paymentMode = "";
    this.paginationVM.filters = this.merchantPaymentFilter;
    this.getUtilities();
  }

  /* search payment */
  searchPaymentHistory() {
    if (this.merchantPaymentFilter.from != null) {
      this.merchantPaymentFilter.from = this.commonService.currentDateFormat(
        this.merchantPaymentFilter.from.getFullYear(),
        this.merchantPaymentFilter.from.getMonth(),
        this.merchantPaymentFilter.from.getDate(),
        this.merchantPaymentFilter.from.getHours(),
        this.merchantPaymentFilter.from.getMinutes(),
        0,
        0
      );
    }
    // tslint:disable-next-line: max-line-length
    if (this.merchantPaymentFilter.to != null) {
      this.merchantPaymentFilter.to = this.commonService.currentDateFormat(
        this.merchantPaymentFilter.to.getFullYear(),
        this.merchantPaymentFilter.to.getMonth(),
        this.merchantPaymentFilter.to.getDate(),
        this.merchantPaymentFilter.to.getHours(),
        this.merchantPaymentFilter.to.getMinutes(),
        0,
        0
      );
    }
    this.showingPage = 0;
    this.paginationVM = <PaginationVM<MerchantPayment, MerchantPaymentFilter>>{
      pageNumber: 0,
      pageSize: 10,
      // pageSize: this.pageSize,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.paymentHistoryList,
      filters: this.merchantPaymentFilter,
    };
    this.paginationVM.filters = this.merchantPaymentFilter;
    this.getPayments();
  }

  onValueChange(fromDate) {
    if (fromDate) {
      this.fToday = fromDate;
    } else {
      this.fToday = this.today;
    }
  }

  /* opt in list export to excel */
  exportToExcel() {
    this.spinner.show();
    this.paymentsService.exportBtxPaymentToExcel(this.merchantId, this.merchantPaymentFilter).subscribe(
      (res) => {
        if (res) {
          res.forEach((item) => {
            const due = new Date(item.dueDate);
            const paymentDate = new Date(item.transactionDate);
            item.dueDate = this.commonService.formatDueDate(due);
            item.transactionDate = this.commonService.formatDueDate(paymentDate);
          });
          const data = _.map(res, function (o) {
            return _.pick(
              o,
              "transactionReference",
              "transactionDate",
              "amountPaid",
              "dueDate",
              "accountNumber",
              "name",
              "paymentChannel",
              "paymentMode"
            );
          });
          const labels =
            "Transaction ref, Transaction Date, Amount Paid ($), Due Date, Account No.,Consumer Name,Payment Channel,Payment Mode";
          const today = new Date();
          const date = this.commonService.formatDate(today);
          this.exportToExcelService.download(data, "Report_" + date, labels);
          this.toaster.success("The request data-sheet created successfully.", "");
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  /* view payment receipt in new tab */
  viewPaymentReceipt(paymentId: string, merchantId: string, utilityId: string) {
    const receipt = "/payment/payment-receipt?id=" + paymentId + "&merchantId=" + merchantId + "&utilityId=" + utilityId;
    this.urls = receipt;
  }
}
