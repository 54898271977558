export class MerchantUser {
    public  Id :string
    public  Role :string
    public  Logo :string
    public  Name :string
    public  Address :string
    public  City :string
    public  State :string
    public  Zip :string
    public  Email :string
    public  Phone :string
    public  Fax :string
    public  ContactName:string 
    public  ContactEmail :string
    public  ContactPhone :string
    public  ContactProfile :string
    public  WebSite :string
    public  MerchantId :string
    public otp: string;
    constructor()
    {
        this.Id = null;
        this.Role = null;
        this.Logo = null;
        this.Name = null;
        this.Address = null;
        this.City = null;
        this.State = null;
        this.Zip = null;
        this.Email = null;
        this.Phone = null;
        this.Fax = null;
        this.ContactName = null;
        this.ContactEmail = null;
        this.ContactPhone = null;
        this.ContactProfile = null;
        this.WebSite = null;
        this.MerchantId = null;
        this.otp = null;
    }
}
