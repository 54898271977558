import { ItemId } from "app/_shared/model/item-id";

export class MerchantPayment {
  public transactionReference: string;
  public transactionDate: string;
  public amountPaid: number;
  public dueDate: string;
  public accountNumber: string;
  public name: string;
  public utility: ItemId;
  public status: string;
  public consumerName: string;
  public paymentType: string;
  public merchantId: string;
  public paymentId: string;
  public paymentChannel: string;
  public paymentMode: string;
  public cardServiceProvider: string;
  public utilityName: string;
  constructor() {
    this.transactionReference = null;
    this.transactionDate = null;
    this.amountPaid = null;
    this.dueDate = null;
    this.accountNumber = null;
    this.name = null;
    this.utility = new ItemId();
    this.status = null;
    this.consumerName = null;
    this.paymentType = null;
    this.merchantId = null;
    this.paymentId = null;
    this.paymentChannel = null;
    this.paymentMode = null;
    this.cardServiceProvider=null;
    this.utilityName = null;
  }
}
