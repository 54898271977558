import { ItemId } from "app/_shared/model/item-id";

export class DemandPaymentReceipt {
  public id: string;
  public firstName: string;
  public lastName: string;
  public transactionId: string;
  public amount: number;
  public accountNumber: string;
  public paymentDateTime: string;
  public email: string;
  public utility: ItemId;
  public merchant: ItemId;
  public merchantLogo: string;
  public field1: string;
  public billType: string;
  public reason: string;
  public paymentFee: number;
  public transactionAmount: number;
  constructor() {
    this.id = null;
    this.firstName = null;
    this.lastName = null;
    this.transactionId = null;
    this.amount = null;
    this.accountNumber = null;
    this.paymentDateTime = null;
    this.email = null;
    this.utility = new ItemId();
    this.merchant = new ItemId();
    this.merchantLogo = null;
  }
}
