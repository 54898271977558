import { Injectable } from "@angular/core";
import { DataService } from "app/_core/data.service";
import { Observable, BehaviorSubject } from "rxjs";
import { MerchantUser } from "app/account/_shared/model/merchant-user";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { MerchantListResult } from "./_shared/merchant-list-result";
import { MerchantSupportSearchFilter } from "./_shared/merchant-support-search-filter";
import { BillSearchModel } from "./_shared/bill-search-model";
import { BillViewModel } from "app/bills/_shared/model/bill-view-model";
import { MerchantBillResult } from "./_shared/merchant-bill-result";
import { IntellipayVM } from "app/payments/_shared/intellipay-vm";
import { Receipt } from "app/_shared/model/receipt-model";
import { NewCustomer } from "./_shared/new-customer";
import { Customers, CustomerFilter } from "./_shared/customers";
import { DemandPaymentReceipt } from "./_shared/demand-payment-receipt";

@Injectable({
  providedIn: "root",
})
export class BillerService {
  private updateUserOnDashboard = new BehaviorSubject(false);
  UserUpdate = this.updateUserOnDashboard.asObservable();

  private updateMsgForDashboard = new BehaviorSubject(false);
  UserShowUpdate = this.updateMsgForDashboard.asObservable();

  constructor(private dataService: DataService) {}

  sendRegistrationOtp(email: string): Observable<string> {
    return this.dataService.post<string, object>("merchant/support/" + email + "/token", {});
  }

  AddBiller(billerSupport: MerchantUser): Observable<any> {
    return this.dataService.post("merchant/support/register", billerSupport);
  }

  getUserList(
    merchantId: string,
    userlist: PaginationVM<MerchantListResult, MerchantSupportSearchFilter>
  ): Observable<PaginationVM<MerchantListResult, MerchantSupportSearchFilter>> {
    return this.dataService.post<
      PaginationVM<MerchantListResult, MerchantSupportSearchFilter>,
      PaginationVM<MerchantListResult, MerchantSupportSearchFilter>
    >(`merchant/support/${merchantId}`, userlist);
  }

  GetMerchantUser(email: string): Observable<MerchantUser> {
    return this.dataService.get<MerchantUser>("account/merchant/profile/" + email);
  }

  SearchBills(search: BillSearchModel): Observable<MerchantBillResult> {
    return this.dataService.post("merchant/support/searchbills", search);
  }

  searchConsumerBills(search: BillSearchModel): Observable<MerchantBillResult[]> {
    return this.dataService.post("merchant/support/seacrchbillsparam", search);
  }

  // intelliPay(merchantId: string): Observable<any> {
  //   return this.dataService.get(`payments/intelliPay/${merchantId}`);
  // }

  intelliPay(): Observable<any> {
    return this.dataService.get("payments/intelliPay/");
  }

  lightboxEMV(): Observable<any> {
    return this.dataService.get("payments/lightboxEMV/");
  }

  lightboxEmvIn(merchantId, utilityId): Observable<any> {
    return this.dataService.get(`payments/lightboxEMV/${merchantId}/${utilityId}`);
  }
  InitializeLightBoxEmvIntelliPayForMcFarland(merchantId): Observable<any> {
    return this.dataService.get(`payments/lightboxEMVformcfarland/${merchantId}`);
  }

  storeIntelliPayResponse(response: IntellipayVM): Observable<Receipt> {
    return this.dataService.post("payments/storeintellipayresponse", response);
  }
  resetPassword(user: MerchantUser): Observable<any> {
    return this.dataService.post("merchant/support/resetPassword", user);
  }

  updateBiller(user: MerchantUser, merchantUserId: string): Observable<any> {
    return this.dataService.post(`merchant/support/updateMerchantUser/${merchantUserId}`, user);
  }

  getProfile(merchantUserId: string): Observable<any> {
    return this.dataService.get(`merchant/support/profile/${merchantUserId}`);
  }

  ActiveDeactiveMerchantUser(isActive: boolean, merchantUserId: string): Observable<any> {
    return this.dataService.get(`merchant/support/activeDiactiveMerchantUser/${isActive}/${merchantUserId}`);
  }

  updateMessageOnNavbar(isReload: boolean) {
    this.updateUserOnDashboard.next(isReload);
  }

  showMessageFrUpdate(isReload: boolean) {
    this.updateMsgForDashboard.next(isReload);
  }

  // storecustomerIntelliPayResponse(response: NewCustomer): Observable<DemandPaymentReceipt> {
  //   return this.dataService.post("merchant/support/storeNewCustomer", response);
  // }

  //2 Feb 2023
  storecustomerIntelliPayResponse(response): Observable<DemandPaymentReceipt> {
    return this.dataService.post("merchant/support/storeadhocpayment", response);
  }

  getCustomers(merchantId: string, filter: PaginationVM<Customers, CustomerFilter>): Observable<PaginationVM<Customers, CustomerFilter>> {
    return this.dataService.post<PaginationVM<Customers, CustomerFilter>, PaginationVM<Customers, CustomerFilter>>(
      `merchant/support/customers/${merchantId}`,
      filter
    );
  }

  demandPaymentReceipt(merchantId: string, paymentId: string): Observable<DemandPaymentReceipt> {
    return this.dataService.get(`merchant/support/demandPaymentReceipt/${merchantId}/${paymentId}`);
  }

  exportAdhocPaymentsToExcel(merchantId, filter: CustomerFilter): Observable<Customers[]> {
    return this.dataService.post(`merchant/support/exportadhocpayments/${merchantId}`, filter);
  }

  //3 feb 2023
  getAdhocPayment(
    merchantId: string,
    filter: PaginationVM<Customers, CustomerFilter>
  ): Observable<PaginationVM<Customers, CustomerFilter>> {
    return this.dataService.post<PaginationVM<Customers, CustomerFilter>, PaginationVM<Customers, CustomerFilter>>(
      `merchant/support/getlist/${merchantId}`,
      filter
    );
  }
  createAdhocPayment(response): Observable<any> {
    return this.dataService.post("payments/createadhocpayment", response);
  }
  reloadTerminals(merchantId): Observable<any> {
    return this.dataService.get(`merchant/support/reloadTerminals/${merchantId}`);
  }
}
